import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AthletesApp } from './SharedComponents';
import AthleteTable from "./AthleteTable";
import { Athlete, AthleteResult, OwnedAthlete, Race, Selection, Weekend } from "./models";
import { useLoaderData } from "react-router-dom";
import { ApplicationComponents, Components } from "./Context";
import { Table } from "./ReactComponents";
import { ApplicationLink } from "./Routing";
import { formatAmount, formatRank, formatScore, HTTP_CLIENT, HTTP_CLIENT_JQUERY_ADAPTER } from "./Util";
import { useRedirectToLoginIfNeeded } from "./Hooks";
import classNames from "classnames";
import { TooltipComponent } from "./TypescriptComponents";
import { Filter } from "./AthleteAppComponents";
import { ColumnDef, Getter } from "@tanstack/react-table";
import { CaptainBandIcon } from "./Images";


const DEFAULT_FILTER: Filter = {
    name: '',
    filterPrice: false
}
export const AthleteView = () => {

    const athlete = useLoaderData() as Athlete;

    return <div>
        <h1 className="entry-title">{ athlete.name }</h1>
        <div className="row">
            <div className="medium-6 columns">
                <AthleteInformationTable athlete={ athlete }/>
            </div>
            <div className="medium-12 columns">
                <ResultTable athleteId={ athlete.athlete_id }/>
            </div>
        </div>
    </div>
}

const AthleteInformationTable = ({ athlete }: { athlete?: Athlete }) => {

    const { flag } = useContext<Components>(ApplicationComponents);

    const data: { title: string | React.JSX.Element, value: any, hasValue?: boolean, settings?: any }[] = [
        {
            title: 'Nation:',
            value: athlete ? <React.Fragment>{ athlete.country } { flag(athlete.country) } </React.Fragment> : ""
        },
        { title: 'Score:', value: athlete?.score || '-' },
        { title: 'Rank:', value: athlete?.rank || 'N/A' },
        { title: 'Gender:', value: athlete?.gender === 'm' ? 'Male' : 'Female' },
        { title: 'Price:', value: athlete?.price },
    ];

    const columns = [
        {
            dataFunction: (data: any) => {
                return {
                    content: data.title,
                    settings: data.settings
                }
            },
        },
        {
            dataFunction: (data: any) => {
                return {
                    isLoading: data.value === undefined,
                    content: data.value,
                }
            },
        }
    ];
    return <React.Fragment>
        <h2>Athlete information</h2>
        <Table columns={ columns } data={ data } config={ { hasHeaders: false } }/>
    </React.Fragment>
}

export const ResultTable = ({ athleteId }: { athleteId: string | number }) => {
    const [results, setResults] = useState<AthleteResult[] | undefined>();
    const [races, setRaces] = useState<Map<number, Race>>(new Map<number, Race>());
    const [weekends, setWeekends] = useState<Map<number, Weekend>>(new Map<number, Weekend>());

    useEffect(() => {

        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: `/api/athletes/${ athleteId }/results`,
        }).then((results: AthleteResult[]) => {
            setResults(results);
        });
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/races'
        }).then((races: Race[]) => {
            setRaces(races.reduce((raceMap, race) => {
                raceMap.set(race.race_id, race);
                return raceMap;
            }, new Map<number, Race>()));
        });
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/weekends'
        }).then((weekends: Weekend[]) => {
            setWeekends(weekends.reduce((weekendMap, weekend) => {
                weekendMap.set(weekend.weekend_id, weekend);
                return weekendMap;
            }, new Map<number, Weekend>()));
        });
    }, [athleteId]);

    const columns = [
        {
            dataFunction: (data: AthleteResult) => {
                return {
                    isLoading: !races.has(data.race_id),
                    content: races.get(data.race_id)?.date as string,
                }
            },
            header: 'Date'
        },
        {
            dataFunction: (data: AthleteResult) => {
                return {
                    isLoading: !races.has(data.race_id) || !weekends.has(races.get(data.race_id)?.weekend_id as number),
                    content: <ApplicationLink href={ `/races/${ data.race_id }` }>
                        { weekends.get(races.get(data.race_id)?.weekend_id as number)?.weekend_name } { races.get(data.race_id)?.name }
                    </ApplicationLink>,
                }
            },
            header: 'Race'
        },
        {
            dataFunction: (data: AthleteResult) => {
                return {
                    content: formatRank(data.rank),
                }
            },
            header: 'Rank'
        },
        {
            dataFunction: (athlete: AthleteResult) => {
                return {
                    content: formatScore(athlete.score),
                }
            },
            header: 'Score'
        }
    ];
    return <Table columns={ columns } data={ results } config={ { loadingRows: 10 } }/>
}

const defaultColumns: ColumnDef<Athlete>[] = [
    {
        header: 'Name',
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
        accessorFn: (athlete) => athlete,
        cell: ({ getValue }: { getValue: Getter<Athlete> }) => <ApplicationLink
            href={ `/athletes/${ getValue().athlete_id }` }>{ getValue().name }</ApplicationLink>,
    },
    {
        header: 'Price',
        accessorFn: (obj: Athlete) => formatAmount(obj.price),
        sortingFn: (a, b) => a.original.price - b.original.price,
        sortDescFirst: true,
    },
    {
        header: 'Score',
        accessorFn: (obj: Athlete) => formatScore(obj.score || 0),
        sortingFn: (a, b) => (a.original.score || 0) - (b.original.score || 0),
        sortDescFirst: true,
    },
    {
        header: 'Nat.',
        accessorKey: 'country',
    },
    {
        header: '',
        accessorKey: 'trade',
        enableSorting: false,
        cell: ({ getValue }: { getValue: Getter<any> }) => getValue()
    },
];

const DefaultAthletesApp = () => {
    return <AthleteApp columns={ defaultColumns }/>;
}

function mergeAthleteWithExtraData(athlete: Athlete, extraData: Map<number, any>) {
    return {
        ...athlete,
        ...(extraData?.get(athlete.athlete_id) || {})
    }
}

export const AthleteApp = ({ columns, extraData, allowBuyingFunction }: {
    columns: ColumnDef<any, any>[],
    extraData?: Map<number, any>,
    allowBuyingFunction?: (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection) => boolean
}) => {
    useRedirectToLoginIfNeeded();
    allowBuyingFunction = allowBuyingFunction || allowBuying;

    function getAthletePicker(balance: number, ownedAthletes: OwnedAthlete[], updateAthleteSelectStatus: (athlete: Athlete, selected: boolean) => void, afterDeadline: boolean, athletes: Athlete[], activeSelection: Selection, flagAsCaptain: (athlete: OwnedAthlete) => void) {
        if (!activeSelection.is_coed) {
            return <AthletePicker balance={ balance }
                                  ownedAthletes={ ownedAthletes }
                                  updateAthleteSelectStatus={ updateAthleteSelectStatus }
                                  activeSelection={ activeSelection }
                                  afterDeadline={ afterDeadline }
                                  athletes={ athletes }
                                  columns={ columns }
                                  flagAsCaptain={ flagAsCaptain }
                                  extraData={ extraData || new Map() }
                                  allowBuyingFunction={ allowBuyingFunction! }
            />;
        }
        if (activeSelection.takes_team_picks) {
            return <CoedTeamAthletePicker balance={ balance }
                                          ownedAthletes={ ownedAthletes }
                                          updateAthleteSelectStatus={ updateAthleteSelectStatus }
                                          activeSelection={ activeSelection }
                                          afterDeadline={ afterDeadline }
                                          athletes={ athletes }
                                          columns={ columns }
                                          flagAsCaptain={ flagAsCaptain }
                                          extraData={ extraData || new Map() }
                                          allowBuyingFunction={ allowBuyingFunction! }
            />
        }
        return <CoedGenderedAthletePicker balance={ balance }
                                          ownedAthletes={ ownedAthletes }
                                          updateAthleteSelectStatus={ updateAthleteSelectStatus }
                                          activeSelection={ activeSelection }
                                          afterDeadline={ afterDeadline }
                                          athletes={ athletes }
                                          columns={ columns }
                                          flagAsCaptain={ flagAsCaptain }
                                          extraData={ extraData || new Map() }
                                          allowBuyingFunction={ allowBuyingFunction! }
        />;
    }

    return (
        <AthletesApp>
            {
                ({
                     balance,
                     ownedAthletes,
                     updateAthleteSelectStatus,
                     afterDeadline,
                     athletes,
                     activeSelection,
                     flagAsCaptain
                 }: AthletePickerProps) => (
                    <div className="App">
                        <h2>My team</h2>
                        <h3 className="balance">Balance: { formatAmount(balance) }</h3>
                        { getAthletePicker(balance, ownedAthletes, updateAthleteSelectStatus, afterDeadline, athletes, activeSelection, flagAsCaptain) }
                    </div>
                )
            }
        </AthletesApp>
    )
}

const allowBuying = (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection)=> {
    if (!activeSelection) {
        return false;
    }
    if (!activeSelection.is_coed) {
        ownedAthletes = ownedAthletes.filter((ownedAthlete) => ownedAthlete.athlete.gender === athlete.gender);
    }
    return athlete.price <= balance
        && ownedAthletes.length < activeSelection.max_picks_per_gender;
}

function loadFilterFromSearchParams(prefix: string): Filter {
    const params = (new URL(document.URL)).searchParams;
    const filteredValues = Array.from(params.entries())
        .filter((value) => value[0].startsWith(prefix))
        .reduce((collector, value) => {
            collector.set(value[0].substring(prefix.length), value[1])
            return collector;
        }, new Map<string, string>());
    return {
        filterPrice: filteredValues.get('FilterPrice')?.toLowerCase() === 'true',
        name: filteredValues.get('Name') || DEFAULT_FILTER.name
    };
}

function saveFilterToSearchParams(prefix: string, filter: Filter) {
    const params = (new URL(document.URL)).searchParams;
    const filteredValues = Array.from(params.entries()).filter((value) => !value[0].startsWith(prefix));
    if (filter.filterPrice !== DEFAULT_FILTER.filterPrice && filter.filterPrice !== undefined) {
        filteredValues.push([prefix + "FilterPrice", filter.filterPrice.toString()]);
    }
    if (filter.name !== DEFAULT_FILTER.name && filter.name !== undefined) {
        filteredValues.push([prefix + "Name", filter.name]);
    }
    let query = filteredValues.reduce((collector, value) => `${ collector }${ value[0] }=${ value[1] }&`, "");
    query = query.substring(0, query.length - 1);
    if (query.length) {
        window.history.replaceState({}, '', `/athletes?${ query }`)
    } else {
        window.history.replaceState({}, '', `/athletes`)
    }
}

const AthletePicker = ({
                           balance,
                           ownedAthletes,
                           updateAthleteSelectStatus,
                           afterDeadline,
                           athletes,
                           activeSelection,
                           columns,
                           flagAsCaptain,
                           extraData,
                           allowBuyingFunction
                       }:
                           AthletePickerProps) => {

    const [maleFilter, setMaleFilter] = useState<Filter>(loadFilterFromSearchParams("men"));
    const [womenFilter, setWomanFilter] = useState<Filter>(loadFilterFromSearchParams("women"));

    function updateMaleFilter(filter: Filter) {
        setMaleFilter(filter);
        saveFilterToSearchParams("men", filter)
    }

    function updateWomenFilter(filter: Filter) {
        setWomanFilter(filter);
        saveFilterToSearchParams("women", filter)
    }

    return <div className="row">
        <div className="medium-6 columns">
            <h3>My Men</h3>
            <OwnedAthletes rows={ activeSelection.maxAthletePicks() / 2 }
                           columns={ 2 }
                           ownedAthletes={ ownedAthletes.filter(athlete => athlete.athlete.gender === "m") }
                           updateAthleteSelectStatus={ updateAthleteSelectStatus }
                           afterDeadline={ afterDeadline }
                           selection={ activeSelection }
                           setAthleteAsCaptain={ flagAsCaptain }/>
            <TeamCaptainExplanation selection={ activeSelection }/>
            <MaleAthleteTable athletes={ athletes }
                              ownedAthletes={ ownedAthletes.filter(athlete => athlete.athlete.gender === "m") }
                              selectAthlete={ updateAthleteSelectStatus }
                              allowBuying={ allowBuyingFunction }
                              afterDeadline={ afterDeadline }
                              columns={ columns }
                              balance={ balance }
                              activeSelection={ activeSelection }
                              filter={ maleFilter }
                              filterUpdate={ updateMaleFilter }
                              extraData={ extraData }
            />
        </div>
        <div className="medium-6 columns">
            <h3>My Women</h3>
            <OwnedAthletes rows={ activeSelection.maxAthletePicks() / 2 }
                           columns={ 2 }
                           ownedAthletes={ ownedAthletes.filter(athlete => athlete.athlete.gender === "f") }
                           updateAthleteSelectStatus={ updateAthleteSelectStatus }
                           afterDeadline={ afterDeadline }
                           selection={ activeSelection }
                           setAthleteAsCaptain={ flagAsCaptain }/>
            <TeamCaptainExplanation selection={ activeSelection }/>

            <FemaleAthleteTable athletes={ athletes }
                                ownedAthletes={ ownedAthletes.filter(athlete => athlete.athlete.gender === "f") }
                                selectAthlete={ updateAthleteSelectStatus }
                                allowBuying={ allowBuyingFunction }
                                afterDeadline={ afterDeadline }
                                columns={ columns }
                                balance={ balance }
                                activeSelection={ activeSelection }
                                filter={ womenFilter }
                                filterUpdate={ updateWomenFilter }
                                extraData={ extraData }
            />
        </div>
    </div>
}

const MaleAthleteTable = ({
                              athletes,
                              afterDeadline,
                              columns,
                              ownedAthletes,
                              selectAthlete,
                              allowBuying,
                              balance,
                              activeSelection,
                              filterUpdate,
                              filter,
                              extraData
                          }: {
    athletes: Athlete[],
    afterDeadline: boolean,
    columns: ColumnDef<any>[],
    ownedAthletes: OwnedAthlete[],
    selectAthlete: (athlete: Athlete, selected: boolean) => void,
    allowBuying: (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection) => boolean,
    balance: number,
    activeSelection: Selection,
    filterUpdate: (filter: Filter) => void,
    filter: Filter,
    extraData: Map<number, any>
}) => {

    const maleAthletes = useMemo(() => {
        return athletes
            .filter(athlete => athlete.gender == 'm' && athlete.is_team === activeSelection.takes_team_picks)
            .map(athlete => mergeAthleteWithExtraData(athlete, extraData))
    }, [athletes, activeSelection, extraData]);

    return <AthleteTable athletes={ maleAthletes }
                         ownedAthletes={ ownedAthletes }
                         selectAthlete={ selectAthlete }
                         allowBuying={ allowBuying }
                         afterDeadline={ afterDeadline }
                         columns={ columns }
                         balance={ balance }
                         activeSelection={ activeSelection }
                         filter={ filter }
                         filterUpdate={ filterUpdate }
    />
}

const MixedTeamAthleteTable = ({
                                   athletes,
                                   afterDeadline,
                                   columns,
                                   ownedAthletes,
                                   selectAthlete,
                                   allowBuying,
                                   balance,
                                   activeSelection,
                                   filterUpdate,
                                   filter,
                                   extraData
                               }: {
    athletes: Athlete[],
    afterDeadline: boolean,
    columns: ColumnDef<any>[],
    ownedAthletes: OwnedAthlete[],
    selectAthlete: (athlete: Athlete, selected: boolean) => void,
    allowBuying: (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection) => boolean,
    balance: number,
    activeSelection: Selection,
    filterUpdate: (filter: Filter) => void,
    filter: Filter,
    extraData: Map<number, any>
}) => {

    const teams = useMemo(() => {
        return athletes
            .filter(athlete => athlete.is_team)
            .map(athlete => mergeAthleteWithExtraData(athlete, extraData))
    }, [athletes, extraData]);

    return <AthleteTable athletes={ teams }
                         ownedAthletes={ ownedAthletes }
                         selectAthlete={ selectAthlete }
                         allowBuying={ allowBuying }
                         afterDeadline={ afterDeadline }
                         columns={ columns }
                         balance={ balance }
                         activeSelection={ activeSelection }
                         filter={ filter }
                         filterUpdate={ filterUpdate }
    />
}

const FemaleAthleteTable = ({
                                athletes,
                                afterDeadline,
                                columns,
                                ownedAthletes,
                                selectAthlete,
                                allowBuying,
                                balance,
                                activeSelection,
                                filterUpdate,
                                filter,
                                extraData
                            }: {
    athletes: Athlete[],
    afterDeadline: boolean,
    columns: ColumnDef<any>[],
    ownedAthletes: OwnedAthlete[],
    selectAthlete: (athlete: Athlete, selected: boolean) => void,
    allowBuying: (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection) => boolean,
    balance: number,
    activeSelection: Selection,
    filterUpdate: (filter: Filter) => void,
    filter: Filter,
    extraData: Map<number, any>
}) => {
    const femaleAthletes = useMemo(() => {
        return athletes
            .filter(athlete => athlete.gender === "f" && athlete.is_team === activeSelection.takes_team_picks)
            .map(athlete => mergeAthleteWithExtraData(athlete, extraData))
    }, [athletes, activeSelection, extraData]);

    return <AthleteTable athletes={ femaleAthletes }
                         ownedAthletes={ ownedAthletes }
                         selectAthlete={ selectAthlete }
                         allowBuying={ allowBuying }
                         afterDeadline={ afterDeadline }
                         columns={ columns }
                         balance={ balance }
                         activeSelection={ activeSelection }
                         filter={ filter }
                         filterUpdate={ filterUpdate }
    />
}

const OwnedAthletes = (props: {
    ownedAthletes: OwnedAthlete[],
    afterDeadline: boolean,
    updateAthleteSelectStatus: (athlete: Athlete, selected: boolean) => void;
    setAthleteAsCaptain: (athlete: OwnedAthlete) => void;
    rows: number,
    columns: number,
    selection: Selection
}) => {

    const {athleteIndicators} = useContext<Components>(ApplicationComponents)

    function fetchAthlete(index: number) {
        if (index < props.ownedAthletes.length) {
            const user_athlete = props.ownedAthletes[index];

            let name = user_athlete.athlete.name;

            if (name.length > 21) {
                name = name.substr(0, 18) + '...';
            }

            return (
                <div>
                    { props.selection.team_captains_enabled
                        && <CaptainBand setAthleteAsCaptain={ props.setAthleteAsCaptain } athlete={ user_athlete }
                                        tradingIsOpen={ props.selection.trading_open }/>
                    }
                    <ApplicationLink
                        href={ `/athletes/${ user_athlete.athlete.athlete_id }` }>{ name }</ApplicationLink>&nbsp;
                    <span>{ formatAmount(user_athlete.bought_for) }</span>
                    {athleteIndicators(user_athlete.athlete)}
                    { !props.afterDeadline &&
                        <button className="button float-right tiny"
                                onClick={ () => sellAthlete(user_athlete.athlete) }>&#10006;</button>
                    }
                </div>
            );
        } else {
            return <div>&nbsp;</div>;
        }
    }

    function sellAthlete(athlete: Athlete) {
        props.updateAthleteSelectStatus(athlete, false);
    }

    function createTableBody() {
        const selected = [];

        for (let row = 0; row < props.rows; row++) {
            const children = [];

            for (let column = 0; column < props.columns; column++) {
                children.push(
                    <td key={ "cell" + row + column } className={ "selected_athlete large-" + (12 / props.columns) }>
                        { fetchAthlete((row * props.columns) + column) }
                    </td>
                );
            }

            selected.push(<tr key={ "row" + row }>{ children }</tr>);
        }

        return selected;
    }

    return (
        <table>
            <tbody>
            { createTableBody() }
            </tbody>
        </table>
    )
}

const CaptainBand = ({ setAthleteAsCaptain, athlete, tradingIsOpen }: {
    setAthleteAsCaptain: (athlete: OwnedAthlete) => void,
    athlete: OwnedAthlete,
    tradingIsOpen: boolean
}) => {

    const tooltipCopy = athlete.is_team_captain ? "Selected team captain" : "Select team captain";
    const selectAsCaptain = () => tradingIsOpen && setAthleteAsCaptain(athlete);
    return <>
        <span className={ classNames("captain-band", { "clickable": tradingIsOpen }) }>
            <span onClick={ selectAsCaptain }
                  className={ classNames("captain", { "notSelected": !athlete.is_team_captain }) }><CaptainBandIcon/></span>

            { (athlete.is_team_captain || tradingIsOpen) &&
                <TooltipComponent text={ tooltipCopy } onClick={ selectAsCaptain }/> }
        </span>
    </>

}

const CoedTeamAthletePicker = ({
                                   balance,
                                   ownedAthletes,
                                   updateAthleteSelectStatus,
                                   afterDeadline,
                                   athletes,
                                   activeSelection,
                                   columns,
                                   flagAsCaptain,
                                   extraData,
                                   allowBuyingFunction
                               }: AthletePickerProps) => {

    const [mixedFilter, setMixedFilter] = useState<Filter>(loadFilterFromSearchParams("mixed"));

    function updateMixedFilter(filter: Filter) {
        setMixedFilter(filter);
        saveFilterToSearchParams("mixed", filter)
    }

    return <div className="row">
        <div className="medium-12 columns">
            <h3>My Athletes</h3>
            <OwnedAthletes rows={ activeSelection.maxAthletePicks() / 2 }
                           columns={ 2 }
                           ownedAthletes={ ownedAthletes }
                           updateAthleteSelectStatus={ updateAthleteSelectStatus }
                           afterDeadline={ afterDeadline }
                           selection={ activeSelection }
                           setAthleteAsCaptain={ flagAsCaptain }/>
            <TeamCaptainExplanation selection={ activeSelection }/>

            <MixedTeamAthleteTable athletes={ athletes }
                                   ownedAthletes={ ownedAthletes }
                                   selectAthlete={ updateAthleteSelectStatus }
                                   allowBuying={ allowBuyingFunction }
                                   afterDeadline={ afterDeadline }
                                   columns={ columns }
                                   balance={ balance }
                                   activeSelection={ activeSelection }
                                   filter={ mixedFilter }
                                   filterUpdate={ updateMixedFilter }
                                   extraData={ extraData }
            />
        </div>
    </div>;
}

const CoedGenderedAthletePicker = ({
                                       balance,
                                       ownedAthletes,
                                       updateAthleteSelectStatus,
                                       afterDeadline,
                                       athletes,
                                       activeSelection,
                                       columns,
                                       flagAsCaptain,
                                       extraData,
                                       allowBuyingFunction
                                   }: AthletePickerProps) => {

    const [maleFilter, setMaleFilter] = useState<Filter>(loadFilterFromSearchParams("men"));
    const [womenFilter, setWomanFilter] = useState<Filter>(loadFilterFromSearchParams("women"));

    function updateMaleFilter(filter: Filter) {
        setMaleFilter(filter);
        saveFilterToSearchParams("men", filter)
    }

    function updateWomenFilter(filter: Filter) {
        setWomanFilter(filter);
        saveFilterToSearchParams("women", filter)
    }

    return <div className="row">
        <div className="medium-12 columns">
            <h3>My Athletes</h3>
            <OwnedAthletes rows={ activeSelection.maxAthletePicks() / 2 }
                           columns={ 2 }
                           ownedAthletes={ ownedAthletes }
                           updateAthleteSelectStatus={ updateAthleteSelectStatus }
                           afterDeadline={ afterDeadline }
                           selection={ activeSelection }
                           setAthleteAsCaptain={ flagAsCaptain }/>
            <TeamCaptainExplanation selection={ activeSelection }/>
        </div>

        <div className="medium-6 columns">
            <MaleAthleteTable athletes={ athletes }
                              ownedAthletes={ ownedAthletes }
                              selectAthlete={ updateAthleteSelectStatus }
                              allowBuying={ allowBuyingFunction }
                              afterDeadline={ afterDeadline }
                              columns={ columns }
                              balance={ balance }
                              activeSelection={ activeSelection }
                              filter={ maleFilter }
                              filterUpdate={ updateMaleFilter }
                              extraData={ extraData }
            />
        </div>
        <div className="medium-6 columns">
            <FemaleAthleteTable athletes={ athletes }
                                ownedAthletes={ ownedAthletes }
                                selectAthlete={ updateAthleteSelectStatus }
                                allowBuying={ allowBuyingFunction }
                                afterDeadline={ afterDeadline }
                                columns={ columns }
                                balance={ balance }
                                activeSelection={ activeSelection }
                                filter={ womenFilter }
                                filterUpdate={ updateWomenFilter }
                                extraData={ extraData }
            />
        </div>
    </div>;
}

const TeamCaptainExplanation = ({ selection }: { selection: Selection }) => {
    return selection.team_captains_enabled ? <p>Team captains will get 50% extra champion points</p> : <></>;
}

interface AthletePickerProps {
    balance: number,
    ownedAthletes: OwnedAthlete[],
    updateAthleteSelectStatus: (athlete: Athlete, selected: boolean) => void,
    flagAsCaptain: (athlete: OwnedAthlete) => void,
    afterDeadline: boolean,
    athletes: Athlete[],
    activeSelection: Selection,
    columns: ColumnDef<any, any>[],
    extraData: Map<number, any>,
    allowBuyingFunction: (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection) => boolean
}

export const SelectionSelector = ({ selections, activeSelectionId, handleClone, handleSwitchActiveSelection }:
                                      {
                                          selections: Selection[],
                                          activeSelectionId: any,
                                          handleClone: any,
                                          handleSwitchActiveSelection: any
                                      }) => {
    const activeSelection = selections.filter((selection) => (selection.selection_id === activeSelectionId))[0];

    const [badgeClasses, setBadgeClasses] = useState<Map<Selection, 'alert' | 'warning' | 'hide'>>(new Map());

    useEffect(() => {
        Promise.all(
            selections.map(selection =>
                HTTP_CLIENT.get<OwnedAthlete[]>(`/api/me/teams/selections/${ selection.selection_id }`)
                    .then((team) => {
                        return { selection, team: team.data }
                    })
            )
        ).then((values) => {
            setBadgeClasses(
                values.reduce((map, val) => {
                        map.set(val.selection, getBadgeClass(val.selection, val.team));
                        return map;
                    }, new Map<Selection, 'alert' | 'warning' | 'hide'>()
                )
            );
        })
    }, [selections]);

    const hasReminder = Array.from(badgeClasses.values())
        .filter(badgeClass => badgeClass !== 'hide')
        .length

    return <div className="row">
        <div className="medium-12 columns">
            <table className="table">
                <thead>
                <tr>
                    <th>Race(s)</th>
                    <th>Deadline</th>
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody>
                { selections.sort((a, b) => new Date(a.trading_close).getTime() - new Date(b.trading_close).getTime()).map((selection) => (
                    <SelectionComponent selection={ selection }
                                        isActive={ selection.selection_id === activeSelectionId }
                                        handleClone={ handleClone }
                                        handleSwitchActiveSelection={ handleSwitchActiveSelection }
                                        key={ selection.selection_id }
                                        activeSelection={ activeSelection }
                                        compatiblePicks={ selection.takes_team_picks === activeSelection.takes_team_picks }
                                        badgeClass={ badgeClasses.get(selection) }/>
                )) }
                </tbody>
            </table>
        </div>
        { (hasReminder &&
            <div className="medium-12 columns">
                <sup>1</sup> You have not selected a team for this selection
            </div>) || <></>
        }
    </div>
};

function getBadgeClass(selection: Selection, team: OwnedAthlete[]): 'alert' | 'warning' | 'hide' {
    const tradingClose = new Date(selection.trading_close);
    const oneDay = 1000 * 60 * 60 * 24;
    if (tradingClose.getTime() - (new Date().getTime() + oneDay) <= 0 && !team.length) {
        return 'alert';
    } else if (tradingClose.getTime() - (new Date().getTime() + oneDay * 3) <= 0 && !team.length) {
        return 'warning';
    }
    return 'hide';
}

const SelectionComponent = (props: any) => {

    const { isActive, selection, activeSelection, handleClone, handleSwitchActiveSelection, compatiblePicks } = props;

    let className = '';

    if (isActive) {
        className = 'activeSelection';
    } else if (new Date(selection.trading_close) < new Date()) {
        className = 'primary';
    }

    const tradingClose = new Date(selection.trading_close);

    return <tr className={ className }>
        <td>{ selection.name }</td>
        <td>
                <span style={ { 'float': 'left' } }>
                    { tradingClose.toLocaleString(undefined, { timeZoneName: 'short' }) }
                </span>
            { selection && <span className={ classNames("badge", props.badgeClass || 'hide') }
                                 style={ { 'float': 'left' } }>1</span> }
        </td>
        <td>{ !isActive && activeSelection.trading_open &&
            <button className="button"
                    onClick={ (event) => handleClone(event, selection.selection_id) }
                    disabled={ !compatiblePicks }>
                Copy this team
            </button>
        }</td>
        <td>{ !isActive &&
            <button className="button" onClick={ (event) => handleSwitchActiveSelection(event, selection) }>
                { selection.trading_open ? 'Pick' : 'View' } team <i className="fi-fast-forward"/>
            </button>
        }</td>
    </tr>
}

export default DefaultAthletesApp;
