import { useContext } from "react";
import { RecaptchaField } from "./ReactComponents";
import { FieldContainer, TextField } from "./TypescriptComponents";
import { SubmitHandler, useForm } from "react-hook-form";
import { RankedUser, Settings } from "./models";
import { SettingsContext } from "./Context";
import { useParams } from "react-router-dom";
import { useDoLogin } from "./Hooks";
import { HTTP_CLIENT } from "./Util";

interface ResetPasswordFields {
    password: string;
    password_confirm: string;
    "g-recaptcha-response": string;
}
export const ResetPasswordPage = () => {
    const settings = useContext<Settings | undefined>(SettingsContext);
    const login = useDoLogin();
    const { register, handleSubmit, formState: { errors }, getValues } = useForm<ResetPasswordFields>();
    const params = useParams();
    const resetId = params.resetId as string;

    const passwordValidation = {
        required: {
            value: true,
            message: "You need a password"
        },
        minLength: {
            value: 8,
            message: "Passwords needs to be at least 8 characters"
        }
    };

    const confirmPasswordValidation = {
        validate: (v: string) => v === getValues("password") || "Passwords does not match",
        required: {
            value: true,
            message: "You need to confirm the password"
        }
    };

    const onSubmit: SubmitHandler<ResetPasswordFields> = data => {
        HTTP_CLIENT.post(`/api/reset/${resetId}`, data)
            .then(() => {
                HTTP_CLIENT.get<RankedUser>('/api/me/rank')
                    .then((response) => {
                        login(response.data);
                    })
                    .catch(() => {
                        window.location.href = '/';
                    });
            });
    };
    return <>
        <h1>Reset password</h1>
        <form onSubmit={handleSubmit(onSubmit)} method="POST"
              name="reset_password_form">
            <FieldContainer error={errors.password?.message} label={"Password"}>
                <TextField registerFields={register("password", passwordValidation)} type={"password"} />
            </FieldContainer>
            <FieldContainer error={errors.password_confirm?.message} label={"Confirm Password"}>
                <TextField registerFields={register("password_confirm", confirmPasswordValidation)} label={"Retype Password"} type={"password"} />
            </FieldContainer>
            <RecaptchaField registerFields={register("g-recaptcha-response")}/>
            <input type="submit" className="button" value={"Reset password"} disabled={!settings} />
        </form>
    </>;
}
