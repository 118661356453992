import { useEffect, useState } from 'react';
import classNames from 'classnames';
import "./scss/tabs.scss";

export const TabComponent = (props: TabComponentProps) => {

    const [selectedTab, setSelectedTab] = useState<string | undefined>(props.selectedId);
    const [data, setData] = useState<JSX.Element | undefined>();

    useEffect(() => {
        if(selectedTab) {
            const tab = props.tabs.find((tab) => tab.id === selectedTab);
            if(tab) {
                setData(tab.renderer());
            }
        }
    }, [selectedTab, props.tabs])

    const tabs = props.tabs.map(tab => {return {
        ...tab,
        select: () => {
            setSelectedTab(tab.id);
        }
    }});

    const hideTabs = props.tabs.length === 1;
    return <div>
        <div className={classNames("tab-container", "columns", {"hide": hideTabs, "medium-3": !hideTabs})}>
            <Tabs tabs={tabs} selectedId={selectedTab}/>
        </div>
        <div className={classNames("columns", {"medium-12": hideTabs, "medium-9": !hideTabs})}>
            {data}
        </div>
    </div>;

}

export const Tabs = (props: TabsProps) => {

    const selectedId = props.selectedId || props.tabs[0].id;
    useEffect(() => {
        if(!props.selectedId) {
            props.tabs[0].select();
        }
    }, [props.selectedId, props.tabs]);

    return <ul className="tabs" data-tabs={true}>
        {props.tabs.map((tab) => <Tab key={tab.id}
                                                    label={tab.label}
                                                    selected={selectedId === tab.id}
                                                    onClick={() => tab.select()}
        />)}
    </ul>;
}

const Tab = (props: TabProps) => {
    return <li className={classNames("tabs-title", {"is-active": props.selected})} onClick={props.onClick}>
        <a aria-selected={props.selected}>
            {props.label}
        </a>
    </li>;
}

interface TabComponentProps {
    tabs: TabData[];
    selectedId?: string;
}

interface TabsProps {
    tabs: HeaderTab[];
    selectedId: string | undefined;
}

interface HeaderTab {
    label: string;
    id: string;
    select: () => void;
}

interface TabProps {
    label: string;
    selected: boolean;
    onClick: () => void;
}

export interface TabData {
    label: string;
    id: string;
    renderer: () => JSX.Element;
}
