import React, { Component, useContext, useEffect, useState } from 'react';
import { hasDefaultTeamName, HTTP_CLIENT, HTTP_CLIENT_JQUERY_ADAPTER } from './Util';
import {
    AthletePopularity,
    League,
    LoadedUser,
    OwnedAthlete,
    PlacedSidebet,
    RankedUser,
    SelectionAthletes,
    Settings,
    Sidebet,
    User,
    UserWeekendResult
} from './models';
import { TabComponent, TabData } from './Tabs';
import { ApplicationComponents, Components, Context, SettingsContext, UserContext } from "./Context";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ApplicationLink } from "./Routing";
import { useRedirectToLoginIfNeeded, useSignOut } from "./Hooks";
import { CountryDropdown, FantasyModal, TextField } from "./TypescriptComponents";
import { SubmitHandler, useForm } from "react-hook-form";
import { SelectionResponse, WeekendResponse } from "./responses";

const AbstractUserPage = (props: AbstractUserPageProps) => {

    const [upcomingWeekend, setUpcomingWeekend] = useState<WeekendResponse | undefined>();
    const components = useContext<Components>(ApplicationComponents);

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/weekends/upcoming',
            success: (upcoming: WeekendResponse) => {
                setUpcomingWeekend(upcoming)
            }
        });
    }, []);

    function createTeamSelection(user: User, upcomingWeekend: WeekendResponse): JSX.Element {
        return <TeamSection user={user} upcomingWeekend={upcomingWeekend} key="selections" />
    }

    if(!upcomingWeekend) {
        return <React.Fragment />
    }
    const user = props.user.user;
    const rank = props.user;
    let teamNameContainer;
    if(hasDefaultTeamName(user)) {
        teamNameContainer = <h2 key="defaultTeamName">{ user.team_name }</h2>
    }
    else {
        teamNameContainer = <h2 key="teamName">{ user.user_name }'s { user.team_name }</h2>
    }
    return <React.Fragment>
        {teamNameContainer}
        <div className="row" key="details">
            <div className="medium-6 columns">
                <h3>User info</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>Score:</td>
                            <td>{rank.score || '-'}</td>
                        </tr>
                        <tr>
                            <td>Overall Rank:</td>
                            <td>{rank.rank || '-'}</td>
                        </tr>
                        <tr>
                            <td>Country:</td>
                            <td className="userCountry">{user.country} {components.flag(user.country)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="medium-6 columns">
                <h3 className="entry-title">Leagues</h3>
                <LeagueTable user={user} usersLeagues={props.usersLeagues} key={'leagueTable'} />
            </div>
        </div>
        {(props.teamSelectionFactory || createTeamSelection)(user, upcomingWeekend)}
        <SidebetsWidget sidebets={upcomingWeekend.sidebets} user={user} key="sidebets" />
        <UserWeekends user={user} key="weekends" />
    </React.Fragment>
}

const UserPage = ({teamSelectionFactory}:
                      {teamSelectionFactory?: (user: User, upcomingWeekend: WeekendResponse) => JSX.Element}) => {

    const context = useContext<Context>(UserContext);
    const navigate = useNavigate();
    const {user, usersLeagues} = useLoaderData() as LoadedUser;
    useEffect(() => {
        if(user.user_id === context.loggedInState.user?.user_id) {
            navigate('/users/me');
        }
    }, [user?.user_id, context.loggedInState.user?.user_id, navigate]);

    if (!user || !usersLeagues) {
        return <React.Fragment />;
    }
    return <AbstractUserPage user={user} usersLeagues={usersLeagues} teamSelectionFactory={teamSelectionFactory} />;
}

const MyUserPage = (props: {teamSelectionFactory?: (user: User, upcomingWeekend: WeekendResponse) => JSX.Element}) => {

    const context = useContext<Context>(UserContext);
    const settings  = useContext<Settings | undefined>(SettingsContext);
    const [usersLeagues, setUsersLeagues] = useState<League[] | undefined>();
    useRedirectToLoginIfNeeded();

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/me/leagues',
            success: (leagues: League[]) => {
                setUsersLeagues(leagues);
            }
        });
    }, []);

    if (!context.loggedInState.user || !usersLeagues || !settings) {
        return <></>;
    }
    return <React.Fragment>
        <AbstractUserPage user={context.loggedInState.user} usersLeagues={usersLeagues}
                          teamSelectionFactory={props.teamSelectionFactory} key={'userPart'} />
        <ChangeTeamName key="changeTeamName"/>
        <ChangeHomeNation country={context.loggedInState.user.user.country} key="changeHomeNation"/>
        <DeleteTeamButton clientId={settings.google_api_key}/>
    </React.Fragment>;
}

interface TeamNameForm { team_name: string }

const ChangeTeamName = () => {

    const context = useContext<Context>(UserContext);
    const settings = useContext<Settings | undefined>(SettingsContext);
    const { register, handleSubmit, setValue } = useForm<TeamNameForm>();
    const onSubmit: SubmitHandler<TeamNameForm> = data => {
        HTTP_CLIENT.patch('/api/me', data)
            .then(() => {
                const timestampKey = 'Timestamp';
                window.localStorage.removeItem(timestampKey);
                const customEvent = new CustomEvent('teamNameUpdated', {detail: {name: data.team_name}});
                document.dispatchEvent(customEvent);
                context.updateUser({team_name: data.team_name});
                setValue("team_name", "");
            });
    }

    if(!settings) {
        return <></>;
    }

    return <>
        <h2>Change Team Name</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
                <TextField registerFields={register("team_name")} className={"input-group-field"} placeholder="New team name" />
                <span className="input-group-button">
                    <input type="submit" className="button" value="Change team name" />
                </span>
            </div>
        </form>
    </>
}

const ChangeHomeNation = ({country}: {country: string}) => {
    const context = useContext<Context>(UserContext);
    const settings = useContext<Settings | undefined>(SettingsContext);
    const { register, handleSubmit } = useForm<{ country: string }>({defaultValues: {country: country}});
    const onSubmit: SubmitHandler<{ country: string }> = data => {
        HTTP_CLIENT.patch('/api/me', data)
            .then(() => {
                context.updateUser({country: data.country});
            });
    }

    if(!settings) {
        return <></>;
    }
    return <>
        <h2>Change Home Nation</h2>
        <p>Current Home Nation: {country}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
                <CountryDropdown register={register} fieldName={"country"} className={"input-group-field"} />
                <span className="input-group-button">
                    <input type="submit" className="button" value="Change nation" />
                </span>
            </div>
        </form>
    </>
}

const LeagueTable = (props: LeagueTableProps) => {
    if(!props.usersLeagues) {
        return <React.Fragment />
    }
    let rows;
    if(props.usersLeagues.length > 0) {
        rows = props.usersLeagues.map((league) => <tr key={league.name}>
            <td><ApplicationLink href={`/leagues/${league.name}`}>{ league.name }</ApplicationLink></td>
        </tr>)
    }
    else {
        rows = <tr><td>{ props.user.user_name } is not a member of any leagues yet</td></tr>
    }
    return <table>
        <tbody>
        {rows}
        </tbody>
    </table>;
}

class TeamSection extends Component<TeamSectionProps, TeamSectionState>{

    constructor(props: TeamSectionProps) {
        super(props);
        this.state = {
            athletes: []
        }
    }

    componentDidMount() {
        Promise.all(this.props.upcomingWeekend.selections.map((selection) => this.fetchTeam(selection)))
            .then((athletes: SelectionAthletes[]) => {
                this.setState({
                    athletes
                });
            });
    }

    private fetchTeam(selection: SelectionResponse): Promise<SelectionAthletes> {
        return new Promise((resolve) => HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: `/api/users/${this.props.user.user_id}/teams/selections/${selection.selection_id}`,
            success: (athletes: OwnedAthlete[]) => {
                HTTP_CLIENT.get<AthletePopularity[]>(selection.links.athlete_popularity)
                    .then((response) => {
                        const athlete_popularities = response.data.reduce((popularities, popularity) =>
                            popularities.set(popularity.athlete_id, popularity.ratio), new Map<number, number>());

                        resolve({
                            athletes,
                            selection: selection,
                            athletePopularities: athlete_popularities,
                            allowedToView: true
                        });
                    })
                    .catch(() => {
                        resolve({
                            athletes,
                            selection: selection,
                            athletePopularities: undefined,
                            allowedToView: true
                        })
                    });

            },
            error: () => {
                resolve({
                    athletes: [],
                    selection: selection,
                    athletePopularities: undefined,
                    allowedToView: false
                });
            }
        }));
    }

    createSelectionAthletesComponent(selection: SelectionAthletes) {
        return <SelectionAthletesComponent {...selection} />;
    }

    render() {
        if(!this.state.athletes) {
            return <React.Fragment />
        }
        let selectedId;
        const passedSelections = this.props.upcomingWeekend.selections
            .filter(selection => !selection.trading_open)
            .sort((s1, s2) => s2.trading_close.localeCompare(s1.trading_close))
        if(passedSelections.length > 0) {
            selectedId = passedSelections[0].selection_id;
        }
        else {
            selectedId = this.props.upcomingWeekend.selections[0].selection_id;
        }

        const tabs: TabData[] = this.state.athletes.map((selection) => {return {
            label: selection.selection.name,
            id: selection.selection.selection_id.toString(),
            renderer: () => this.createSelectionAthletesComponent(selection)
        }})

        return [
            <h2 key="header">Team{ this.props.upcomingWeekend.team_per_race && 's'} for {this.props.upcomingWeekend.weekend_name}</h2>,
            <div className="row" key="row">
                <TabComponent selectedId={selectedId.toString()} tabs={tabs} />
            </div>
        ]
    }
}

const SelectionAthletesComponent = (props: SelectionAthletesProps) => {

    function createAthleteTable(athletes: OwnedAthlete[], athletePopularities: Map<number, number> | undefined) {
        return <AthleteTable athletes={athletes} athletePopularities={athletePopularities} />
    }

    const createTable = props.athleteTableFunction || createAthleteTable;

    let athletes;
    if(props.allowedToView) {
        if(props.selection.is_coed) {
            athletes = <div className="row">
                <div className="columns medium-6">
                    <h3>{props.selection.takes_team_picks ? 'Teams' : 'Athletes'}</h3>
                    {createTable(props.athletes, props.athletePopularities)}
                </div>
            </div>;
        }
        else {
            athletes = <div className="row">
                <div className="columns medium-6">
                    <h3>Men</h3>
                    {createTable(props.athletes.filter((athlete) => athlete.athlete.gender === 'm'), props.athletePopularities)}
                </div>
                <div className="columns medium-6">
                    <h3>Women</h3>
                    {createTable(props.athletes.filter((athlete) => athlete.athlete.gender === 'f'), props.athletePopularities)}
                </div>
            </div>;
        }
    }
    else {
        athletes = <div
            id="panel-hidden">
            <div className="callout">
                <h5>Athlete selections hidden!</h5>
                <p>Athlete selections for these races are hidden until after selection deadline.</p>
            </div>
        </div>;
    }
    return <div
        key={props.selection.selection_id}>
        {athletes}
    </div>;
}

export interface SelectionAthletesProps {
    selection: SelectionResponse;
    athletes: OwnedAthlete[];
    allowedToView: boolean;
    athletePopularities: Map<number, number> | undefined;
    athleteTableFunction?: (athletes: OwnedAthlete[]) => JSX.Element;
}

const AthleteTable = (props: AthleteTableProps) => {
    const components = useContext<Components>(ApplicationComponents);

    function createHeader(showPopularity: boolean) {
        if (!showPopularity) {
            return <thead />;
        } else {
            return <thead>
                <tr>
                    <th></th>
                    <th>Popularity</th>
                </tr>
            </thead>;
        }
    }

    function createRow(athlete: OwnedAthlete, popularity: number | undefined) {
        return <tr key={athlete.athlete.athlete_id}>
            <td>
                {components.athleteDisplay(athlete.athlete)}
            </td>
            <td>
                {popularity && `${Math.round(popularity * 100)}%`}
            </td>
        </tr>
    }

    const rows = props.athletes.map((athlete) => createRow(
        athlete,
        props.athletePopularities ? (props.athletePopularities?.get(athlete.athlete_id) || 0) : undefined
    ));
    return <table className="table">
        {createHeader(props.athletePopularities !== undefined)}
        <tbody>
            {rows}
        </tbody>
    </table>
}

const SidebetsWidget = (props: SidebetsWidgetProps) => {
    if(!props.sidebets.length) {
        return <React.Fragment />
    }
    const bets = props.sidebets.map((bet) => <SidebetWidget key={bet.sidebet_id} sidebet={bet} user={props.user} />);
    return <div className="row">
        <div className="medium-6 columns">
            <h4>{`This week's sidebet${props.sidebets.length > 1 ? "s" : ""}:`}</h4>
            {bets}
        </div>
    </div>
}

class SidebetWidget extends Component<SidebetWidgetProps, SidebetWidgetState> {

    constructor(props: SidebetWidgetProps) {
        super(props);
        this.state = {
            answer: null
        }
    }

    componentDidMount() {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: `/api/users/${this.props.user.user_id}/placed_sidebets/${this.props.sidebet.sidebet_id}`,
            success: (placedSidebet: PlacedSidebet) => {
                if(placedSidebet.sidebet_option) {
                    this.setState({
                        answer: placedSidebet.sidebet_option.description
                    });
                }
                else {
                    this.setState({
                        answer: 'Didn’t place a sidebet'
                    });
                }
            },
            error: (response) => {
                if(response.status === 403) {
                    this.setState({
                        answer: "Secret"
                    });
                }
            }
        });
    }

    render() {
        return [
            <p key={'description'}>
                <i>{this.props.sidebet.description}</i> (for {this.props.sidebet.value} points)
            </p>,
            <p key={'bet'}>
                <b>Placed bet:</b> {this.state.answer || "-"}
            </p>
        ];
    }
}

class UserWeekends extends Component<UserWeekendsProps, UserWeekendsState> {

    constructor(props: UserWeekendsProps) {
        super(props);
        this.state = {
            results: []
        }
    }

    componentDidMount() {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: `/api/users/${this.props.user.user_id}/weekend_results`,
            success: (results: UserWeekendResult[]) => {
                this.setState({
                    results
                })
            }
        });
    }

    render() {
        const results = this.state.results.map((result) => <UserWeekendResultWidget key={result.weekend_id} result={result} />)
        return <div className="row column">
            <h2 className="entry-title">Weekend Results</h2>
            {results}
        </div>;
    }
}

const UserWeekendResultWidget = (props: UserWeekendResultProps) => {

    return <div className="card floating-card">
        <div className="card-divider">
            <ApplicationLink href={ `/events/${props.result.weekend_id}` }>{props.result.weekend_name}</ApplicationLink>
        </div>
        <div className="card-section">
            <p>Score: {props.result.score}</p>
            <p>Rank: {props.result.weekend_rank}</p>
        </div>
    </div>;
}

const DeleteTeamButton = ({clientId}: {clientId: string | null}) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const signOut = useSignOut(clientId);
    function deleteTeam() {
        HTTP_CLIENT.delete('/api/me').then(signOut);
        setShowDialog(false);
    }
    return <>
        <FantasyModal isOpen={showDialog}
                      close={(accepted => accepted ? deleteTeam() : setShowDialog(false))}
                      title="Delete league"
                      message="Are you sure you want to delete your user? This action can not be reversed!"/>
        <div className="input-group" key="deleteTeamButton">
            <button id="deleteUser" type="button" className="button alert" name="" onClick={() => setShowDialog(true)}>Delete user</button>
        </div>
    </>
}

interface UserWeekendResultProps {
    result: UserWeekendResult
}

interface UserWeekendsProps {
    user: User;
}

interface UserWeekendsState {
    results: UserWeekendResult[]
}

interface SidebetWidgetProps {
    sidebet: Sidebet;
    user: User;
}

interface SidebetWidgetState {
    answer: string | null;
}

interface SidebetsWidgetProps {
    sidebets: Sidebet[];
    user: User;
}

interface AthleteTableProps {
    athletes: OwnedAthlete[];
    athletePopularities: Map<number, number> | undefined
}

interface TeamSectionProps {
    user: User;
    upcomingWeekend: WeekendResponse;
}

interface TeamSectionState {
    athletes: SelectionAthletes[];
}

interface LeagueTableProps {
    usersLeagues: League[] | null;
    user: User;
}

interface AbstractUserPageProps {
    user: RankedUser;
    usersLeagues: League[];
    teamSelectionFactory: ((user: User, upcomingWeekend: WeekendResponse) => JSX.Element) | undefined;
}

export {UserPage, MyUserPage, LeagueTable, TeamSection, SidebetsWidget, UserWeekends, ChangeTeamName, ChangeHomeNation, AbstractUserPage, SelectionAthletesComponent}

