import React, { useContext, useEffect, useState } from "react";
import { Athlete, AthletePopularity, AthletePrice, AthleteResult, LoadedRaceData, Race, RankedUser } from "./models";
import { Table } from "./ReactComponents";
import { useLoaderData } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ApplicationLink } from "./Routing";
import { ApplicationComponents, Components } from "./Context";
import {
    calculatePPG,
    formatNullableRank,
    formatNullableScore,
    formatPPG,
    formatRank,
    formatScore,
    HTTP_CLIENT,
    HTTP_CLIENT_JQUERY_ADAPTER
} from "./Util";
import { LoadingContainer, ReactTable } from "./SharedComponents";
import { ColumnDef, Getter } from "@tanstack/react-table";
import { SelectionResponse, WeekendResponse } from "./responses";

export const RacePage = ({athleteTable}: {athleteTable: (race?: Race, selection?: SelectionResponse, weekend?: WeekendResponse) => React.JSX.Element}) => {

    const loaderData = useLoaderData() as LoadedRaceData;
    const race = loaderData.race;
    const weekend = loaderData.weekend;
    const selection = loaderData.selection;

    return <div>
        <h1>{ (weekend && race) ? (weekend.weekend_name + " " + race.name) : <Skeleton /> }</h1>
        <div className="row">
            <div className="columns medium-6">
                <RaceInformationTable weekend={weekend} race={race} />
            </div>
            <div className="columns medium-6">
                <UserTable race={race} />
            </div>
        </div>
        <div className="row">
            <div className="columns medium-12">
                {athleteTable(race, selection, weekend)}
            </div>
        </div>
    </div>
}

export const RaceInformationTable = ({race, weekend}: {race?: Race, weekend?: WeekendResponse}) => {

    const [userScore, setUserScore] = useState<RankedUser | undefined>();
    const { flag } = useContext<Components>(ApplicationComponents);

    useEffect(() => {
        if(race) {
            HTTP_CLIENT_JQUERY_ADAPTER.get({
                url: `/api/me/race_results/${race.race_id}`
            }).then((score) => setUserScore(score))
        }
    }, [race]);

    const data: {title: string | JSX.Element, value: any, hasValue?: boolean, settings?: any}[] = [
        {title: 'My Score', value: userScore?.score === null ? '-' : userScore?.score},
        {title: 'My Rank', value: userScore?.rank === null ? 'N/A' : userScore?.rank},
        {title: 'Country', value: <React.Fragment>{ race?.country } { race && flag(race.country) }</React.Fragment>},
        {title: 'Distance', value: race?.distance},
        {title: 'Discipline', value: race?.discipline},
        {title: 'Date', value: race?.date},
        {title: 'Gender', value: race && race.gender === 'm' ? 'Male' : 'Female'},
        {title: 'Weekend', value: weekend && <ApplicationLink href={ `/events/${weekend.weekend_id}` }>
            { weekend.weekend_name }
        </ApplicationLink>},
    ];

    if(race && !race.include_in_total) {
        const playerInfo = <p style={{"color": "red"}}>
            Please note that the results from this race will not be included in the total scores for
            players and athletes.
        </p>;
        data.push({title: playerInfo, value: undefined, hasValue: false, settings: {colSpan: 2}})
    }

    const columns = [
        {
            dataFunction: (data: any) => { return {
                content: data.title,
                settings: data.settings
            }},
            headerKey: 'category'
        },
        {
            dataFunction: (data: any) => { return {
                isLoading: data.value === undefined,
                content: data.value,
                shouldRender: data.hasValue === undefined || data.hasValue,
            }},
            headerKey: 'value'
        }
    ];

    return <>
        <h2>Race Information</h2>
        <Table config={{hasHeaders: false}} data={data} columns={columns} />
    </>
}

export const AthleteTable = ({ race, selection, weekend }: { race?: Race, selection?: SelectionResponse, weekend?: WeekendResponse}) => {
    const [usersTeam, setUsersTeam] = useState<Athlete[] | undefined>();
    const [bestAthletes, setBestAthletes] = useState<AthleteResult[] | undefined>();
    const [athletePrices, setAthletePrices] = useState<Map<number, number> | undefined>();
    const [athletePopularities, setAthletePopularities] = useState<Map<number, number> | undefined>();
    const {athleteDisplay} = useContext<Components>(ApplicationComponents);

    useEffect(() => {
        if(race) {
            HTTP_CLIENT_JQUERY_ADAPTER.get({
                url: `/api/races/${race.race_id}/results/athletes`
            }).then((race) => setBestAthletes(race));
            HTTP_CLIENT_JQUERY_ADAPTER.get({
                url: `/api/me/teams/selections/${race.selection_id}`
            }).then((team) => setUsersTeam(team));
        }
    }, [race]);

    useEffect(() => {
        if(weekend) {
            HTTP_CLIENT.get<AthletePrice[]>(weekend.links.prices)
                .then((response) => {
                    const weekend_prices = response.data.reduce((prices, price) =>
                        prices.set(price.athlete_id, price.price), new Map<number, number>());
                    setAthletePrices(weekend_prices);
                });
        }
    }, [weekend]);

    useEffect(() => {
        if (selection) {
            HTTP_CLIENT.get<AthletePopularity[]>(selection.links.athlete_popularity)
                .then((response) => {
                    const athletePopularity = response.data.reduce((popularities, popularity) =>
                        popularities.set(popularity.athlete_id, popularity.ratio), new Map<number, number>());

                    setAthletePopularities(athletePopularity);
                });
        }
    }, [selection]);

    const columns: ColumnDef<AthleteResult>[] = [
        {
            accessorFn: (data: AthleteResult) => formatRank(data.rank),
            header: '',
            id: 'athlete rank'
        },
        {
            accessorFn: (data: AthleteResult) => data.athlete,
            cell: ({ getValue }: { getValue: Getter<Athlete>}) => athleteDisplay(getValue()),
            header: 'Name',
            id: 'name',
            enableSorting: false
        },
        {
            accessorFn: (data: AthleteResult) => data.score,
            cell: ({ getValue }: { getValue: Getter<number>}) => formatScore(getValue()),
            header: 'Points'
        },
        {
            accessorFn: (data: AthleteResult) => data,
            cell: ({ getValue }: { getValue: Getter<Athlete>}) =>
                <LoadingContainer data={usersTeam} mapper={(team) => team.find((userAthlete) => userAthlete.athlete_id === getValue().athlete_id) ? '✔' : ' '} />,
            header: 'In my team?',
            enableSorting: false
        },
        {
            accessorFn: (data: AthleteResult) => data,
            cell: ({ getValue }: { getValue: Getter<AthleteResult>}) => <LoadingContainer data={athletePrices} mapper={(prices) => formatPPG(calculatePPG(getValue().athlete, prices, getValue().score))} />,
            header: 'PPG',
            id: 'ppg',
            sortingFn: (a, b) => {
                if (!athletePrices) {
                    return 0;
                }
                const aPPG = calculatePPG(a.original.athlete, athletePrices, a.original.score);
                const bPPG = calculatePPG(b.original.athlete, athletePrices, b.original.score);
                if (aPPG === undefined && bPPG === undefined) {
                    return 0;
                }
                if (aPPG === undefined) {
                    return 1;
                }
                if (bPPG === undefined) {
                    return 1;
                }
                return aPPG - bPPG;
            }
        },
        {
            accessorFn: (data: AthleteResult) => data,
            cell: ({ getValue }: { getValue: Getter<AthleteResult>}) =>
                <LoadingContainer data={athletePopularities} mapper={(popularities) => `${Math.round((popularities.get(getValue().athlete_id) || 0) * 100)}%`} />,
            header: 'Popularity',
            sortingFn: (a, b) => {
                if (!athletePopularities) {
                    return 0;
                }
                const aPopularity = athletePopularities.get(a.original.athlete_id) || 0;
                const bPopularity = athletePopularities.get(b.original.athlete_id) || 0;
                return aPopularity - bPopularity;
            }
        }
    ];
    return <React.Fragment>
        <h2>Race Results</h2>
        <ReactTable columns={columns} data={bestAthletes || []} pageSize={20} />
    </React.Fragment>
}

export const UserTable = ({ race }: { race?: Race }) => {

    const [bestUsers, setBestUsers] = useState<RankedUser[] | undefined>();
    const { userDisplay } = useContext<Components>(ApplicationComponents);

    useEffect(() => {
        if(race) {
            HTTP_CLIENT_JQUERY_ADAPTER.get({
                url: `/api/races/${race.race_id}/results/users`
            }).then((race) => setBestUsers(race));
        }
    }, [race]);

    const columns = [
        {
            dataFunction: (data: RankedUser) => { return {
                content: formatNullableRank(data.rank, 'N/A'),
            }},
            headerKey: 'user rank'
        },
        {
            dataFunction: (data: RankedUser) => { return {
                content: userDisplay(data.user),
            }},
            headerKey: 'user name'
        },
        {
            dataFunction: (data: RankedUser) => { return {
                content: formatNullableScore(data.score, '-'),
            }},
            headerKey: 'user score'
        },
    ];
    return <React.Fragment>
        <h2>Top Players</h2>
        <Table config={{hasHeaders: false}} columns={columns} data={bestUsers} />
    </React.Fragment>
}
