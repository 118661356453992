import React from "react";
import { Athlete, Country, Cup, ErrorState, RankedUser, Settings, User } from "./models";
import { HttpService } from "./Util";

export const CountryContext = React.createContext<CountryData>({});

export interface CountryData {
    countries?: Country[];
    countriesByAlpha3?: Map<string, Country>;
}

export const CupContext = React.createContext<Cup[]>([]);

export const ApplicationComponents = React.createContext<Components>({
    flag: () => <React.Fragment />,
    userDisplay: () => <React.Fragment />,
    athleteDisplay: () => <React.Fragment />,
    athleteIndicators: () => <React.Fragment />,
    httpService: new HttpService()
});

export interface Components {
    flag: (country: string) => React.JSX.Element;
    userDisplay: (user: User) => React.JSX.Element;
    athleteDisplay: (athlete: Athlete) => React.JSX.Element;
    athleteIndicators: (athlete: Athlete) => React.JSX.Element;
    httpService: HttpService;
}

export const UserContext = React.createContext<Context>({
    loggedInState: {loggedIn: false, user: undefined},
    updateUser: () => {},
    loginUser: () => {},
    logoutUser: () => {},
});

export interface Context {
    loggedInState: LoginState;
    updateUser: (user: Partial<User>) => void;
    loginUser: (user: RankedUser) => void;
    logoutUser: () => void;
}

export interface LoginState {
    loggedIn: boolean;
    user: RankedUser | undefined;
}

export const SettingsContext = React.createContext<Settings | undefined>(undefined);

export const ErrorContext = React.createContext<ErrorState>({
    hasError: false,
    displayError: () => {},
});
