import React, { useContext, useEffect, useState } from "react";
import {
    Cup,
    RankedUser,
    ApplicationComponents,
    Components,
    CupContext,
    HTTP_CLIENT_JQUERY_ADAPTER,
    formatScore,
    formatRank
} from "@fantasy/components";

export const StandingsView = () => {
  const cups = useContext<Cup[]>(CupContext);

  const tables = cups.map((cup) => <StandingTable cup={cup} key={cup.name} />);
  return (
    <div>
      <h1>Standings</h1>
      {tables}
    </div>
  );
};

const StandingTable = ({ cup }: { cup: Cup }) => {
  const [leaderboard, setLeaderbord] = useState<RankedUser[] | null>(null);
  const { userDisplay } = useContext<Components>(ApplicationComponents);

  useEffect(() => {
    HTTP_CLIENT_JQUERY_ADAPTER.get({
      url: `/api/cups/${cup.name}/leaderboard`,
      success: (leaderboard: RankedUser[]) => {
        setLeaderbord(leaderboard);
      },
    });
  }, [cup.name]);
  if (leaderboard === null) {
    return <React.Fragment />;
  }
  if (leaderboard.length === 0) {
    return (
      <div>
        <h3>{cup.name} Cup</h3>
        <h4>
          No standings yet, still waiting for the first race to be scored!
        </h4>
      </div>
    );
  }

  const users = leaderboard.map((user) => (
    <tr key={user.user_id}>
      <td className={"small-1"}>{formatRank(user.rank || 0)}.</td>
      <td className={"small-10"}>{userDisplay(user.user)}</td>
      <td className={"small-1"}>{formatScore(user.score || 0)}</td>
    </tr>
  ));
  return (
    <div>
      <h3>{cup.name} Cup</h3>
      <table className={"leaderboard"}>
        <tbody>{users}</tbody>
      </table>
    </div>
  );
};
