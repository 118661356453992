import "./_settings.scss";

import { createRoot } from "react-dom/client";
import { ApplicationRouting, CopyPage, Routes } from "@fantasy/components";
import { NHFNMainPage } from "./Base";
import { StandingsView } from "./StandingsView";

import { RouteObject, RouterProvider } from "react-router-dom";

class NHFNRouting extends Routes {
    getBaseElement() {
        return <NHFNMainPage />;
    }

    appSpecificViews(): RouteObject[] {
        return [
            {
                path: "standings",
                children: [],
                element: <StandingsView />,
            },
            {
                path: "info",
                children: [
                    {
                        path: "contact",
                        element: <CopyPage />,
                        children: [],
                    },
                    {
                        path: "rules",
                        element: <CopyPage />,
                        children: [],
                    },
                ],
            },
        ];
    }
}

const routing = new NHFNRouting();
createRoot(document.getElementById("root") as Element).render(
    <RouterProvider router={ApplicationRouting({ data: routing })} />,
);
