import { useContext, useState } from 'react';
import {
    CountryDropdown,
    EmailField,
    FieldContainer,
    TextField,
    UsernameField
} from './TypescriptComponents';
import { GoogleRegisterButton } from "./GoogleSigninButton";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { RecaptchaField } from "./ReactComponents";
import { useDoLogin } from "./Hooks";
import { RankedUser, Settings } from "./models";
import { SettingsContext } from "./Context";
import { displayErrorMessage, EmailValidation, ErrorResponse, HTTP_CLIENT } from "./Util";

interface RegisterFields {
    email: string;
    user_name: string;
    password: string;
    password_confirm: string;
    country: string;
    teamName: string;
    register_source: string;
    "g-recaptcha-response": string;
}

export const RegisterUserPage = () => {
    const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map<string, string>());
    const [searchParams] = useSearchParams();
    const settings = useContext<Settings | undefined>(SettingsContext);

    const login = useDoLogin();
    const defaultValues: Partial<RegisterFields> = Object.fromEntries(searchParams.entries());
    const isInOAuthFlow = !!searchParams.get("register_source");
    const {register, handleSubmit, formState: {errors}, watch} = useForm<RegisterFields>({defaultValues});
    const onSubmit: SubmitHandler<RegisterFields> = data => {
        HTTP_CLIENT.post('/api/register', data, undefined, {useGlobalErrorHandling: false})
            .then(() => {
                HTTP_CLIENT.get<RankedUser>('/api/me/rank')
                    .then((response) => {
                        login(response.data);
                    })
                    .catch(() => {
                        window.location.href = '/';
                    });
            })
            .catch((error: ErrorResponse<any>) => {
                if (error.response?.data.response) {
                    if (error.response.data.response.field_errors) {
                        setFormErrors(new Map(Object.entries(error.response.data.response.field_errors)));
                    } else {
                        displayErrorMessage(error.response.data.response.errors);
                    }
                } else {
                    displayErrorMessage("Something went wrong, please refresh the webpage");
                }
            });
    }

    const copyMap = new Map<string, string>(formErrors);
    if (errors) {
        for (const field in errors) {
            const value = (errors as any)[field];
            if (value.message) {
                copyMap.set(field, value.message);
            }
        }
    }

    const passwordValidation = {
        required: {
            value: !isInOAuthFlow,
            message: "You need a password"
        },
        minLength: {
            value: 8,
            message: "Passwords needs to be at least 8 characters"
        }
    };

    const confirmPasswordValidation = {
        validate: (v: string) => v === watch("password") || "Passwords does not match",
        required: {
            value: !isInOAuthFlow,
            message: "You need to confirm the password"
        }
    };

    const UsernameValidation = {
        required: {
            value: true,
            message: "Username must be provided"
        },
        maxLength: {
            value: 120,
            message: "Usernames can be max 120 characters"
        },
        minLength: {
            value: 3,
            message: "Usernames must be at least 3 characters"
        }
    };

    return <>
        <h1>Register</h1>
        { !isInOAuthFlow && <GoogleRegisterButton/> }
        <form onSubmit={ handleSubmit(onSubmit) }>
            <FieldContainer error={ copyMap.get("email") } label={ "Email" }>
                <EmailField register={ register("email", EmailValidation) } disabled={ searchParams.has("email") }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("user_name") } label={ "Username" }>
                <UsernameField register={ register("user_name", UsernameValidation) }
                               disabled={ searchParams.has("user_name") }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("password") } label={ isInOAuthFlow ? undefined : "Password" }>
                <TextField registerFields={ register("password", passwordValidation) } type={ "password" }
                           disabled={ searchParams.has("password") } hidden={ isInOAuthFlow }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("password_confirm") }
                            label={ isInOAuthFlow ? undefined : "Confirm Password" }>
                <TextField registerFields={ register("password_confirm", confirmPasswordValidation) }
                           hidden={ isInOAuthFlow } label={ "Retype Password" } type={ "password" }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("country") } label={ "Country" }>
                <CountryDropdown register={ register } fieldName={ "country" }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("teamName") } label={ "Team Name" }>
                <TextField registerFields={ register("teamName") } disabled={ searchParams.has("teamName") }/>
            </FieldContainer>
            <FieldContainer>
                <TextField registerFields={ register("register_source") } type={ "text" } hidden={ true }/>
            </FieldContainer>
            <RecaptchaField registerFields={ register("g-recaptcha-response") }/>
            <input type="submit" className="button" value={ "Register" } disabled={ !settings }/>
        </form>
    </>;
}
