import React, { useContext, useEffect, useMemo, useState } from "react"
import { RankedUser } from "./models";
import Skeleton from "react-loading-skeleton";
import { ApplicationComponents, Components } from "./Context";
import "./scss/overall.scss";
import { FilterConfig, ReactTable } from "./SharedComponents";
import { ColumnDef, Getter, Row } from "@tanstack/react-table";
import { formatNullableRank, formatNullableScore } from "./Util";

export const OverallPage = () => {

    const [users, setUsers] = useState<RankedUser[] | undefined>();
    const components = useContext<Components>(ApplicationComponents);

    useEffect(() => {
        const httpService = components.httpService;
        httpService.overallRanking(3000).then((users: RankedUser[]) => setUsers(users));
    }, [components.httpService]);

    const columns: ColumnDef<RankedUser | undefined>[] = [
        {
            header: "Rank",
            id: "Rank",
            accessorFn: (user) => user,
            cell: ({ getValue }: { getValue: Getter<RankedUser | undefined>}) => {
                const user = getValue();
                if (user) {
                    return formatNullableRank(user.rank, '-')
                }
                else {
                    return <Skeleton />;
                }

            },
            enableGlobalFilter: true,
            enableSorting: false
        },
        {
            header: "Team name",
            id: "Team name",
            accessorFn: (user) => user,
            cell: ({ getValue }: { getValue: Getter<RankedUser | undefined>}) => {
                const user = getValue();
                if (user) {
                    return components.userDisplay(user.user)
                }
                else {
                    return <Skeleton />;
                }

            },
            enableGlobalFilter: true,
            enableSorting: false
        },
        {
            header: "Score",
            id: "Score",
            accessorFn: (user) => user,
            cell: ({ getValue }: { getValue: Getter<RankedUser | undefined>}) => {
                const user = getValue();
                if (user) {
                    return formatNullableScore(user.score, 0);
                }
                else {
                    return <Skeleton />;
                }

            },
            enableGlobalFilter: true,
            enableSorting: false
        }
    ];

    const pageSize = 100;

    const rows = users || Array.from(Array(pageSize));

    const filterConfig: FilterConfig<RankedUser | undefined, UserFilter> = useMemo(() => {
        return {
            filterFunction: (rows: Row<RankedUser | undefined>, globalFilterValue: UserFilter) => {
                const data = rows.original;
                if (!data) {
                    return true;
                }
                return data.user.team_name.toLowerCase().indexOf(globalFilterValue.name.toLowerCase()) > -1
            },
            createFilterComponent: (globalFilter: UserFilter | undefined, setGlobalFilter: (filter: UserFilter) => void) => {
                return <input className="table_filter" type="text" placeholder="Search user" value={globalFilter?.name || ""}
                              onChange={(event) => {
                                  setGlobalFilter({name: event.target.value});
                              }} />
            }
        }
    }, []);

    return <div className="overallPage">
        <h2 className="entry-title">Overall Player Standings</h2>
        <ReactTable columns={ columns } data={ rows } filterConfig={ filterConfig } pageSize={ pageSize } />
    </div>
}

interface UserFilter {
    name: string;
}
