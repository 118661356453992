import { ApplicationLink, SiteBase } from "@fantasy/components";
import { NhfnMainMenu } from "./MainMenu";
import { ServerError } from "./ErrorPages";
import Signature from "./img/signature-inverted.png";
import { AppLogo } from "@fantasy/components/src/components/SiteBase.tsx";
import logo from '/site-logo.png'

export const NHFNMainPage = () => (
  <SiteBase
    mainMenu={<NhfnMainMenu />}
    footer={<NHFNSiteFooter />}
    subFooter={<NHFNSubFooter />}
    errorPage={<ServerError />}
    logo={<AppLogo logoPath={logo} />}
  />
);

export const NHFNSubFooter = () => (
  <div id="subfooter">
    <div className="row">
      <div className="medium-9 columns">
        <a href="//noahhoffman.com" target="_blank" rel="noreferrer">
          <img alt="Noah Hoffman Signature" src={Signature} />
        </a>
      </div>
      <div className="medium-3 columns">
        <a target="_blank" href="//twitter.com/fantasyxc" rel="noreferrer">
          <i className="fi-social-twitter" />
        </a>
      </div>
    </div>
  </div>
);

export const NHFNSiteFooter = () => (
  <footer>
    <div id="sitemap">
      <div className="row">
        <div className="medium-3 columns">
          <h4>Play the game</h4>
          <ul>
            <li>
              <ApplicationLink href={"/info/rules"}>
                How to play
              </ApplicationLink>
            </li>
          </ul>
        </div>
        <div className="medium-3 columns">
          <h4>Standings</h4>
          <ul>
            <li>
              <ApplicationLink href={"/users/me"}>My Team</ApplicationLink>
            </li>
            <li>
              <ApplicationLink href={"/overall"}>
                Overall player ranking
              </ApplicationLink>
            </li>
            <li>
              <ApplicationLink href={"/leagues"}>Leagues</ApplicationLink>
            </li>
            <li>
              <ApplicationLink href={"/athletes"}>Athletes</ApplicationLink>
            </li>
          </ul>
        </div>
        <div className="medium-3 columns">
          <h4>Schedule</h4>
          <ul>
            <li>
              <ApplicationLink href={"/events"}>Events</ApplicationLink>
            </li>
          </ul>
        </div>
        <div className="medium-3 columns">
          <h4>Get in touch</h4>
          <ul>
            <li>
              <ApplicationLink href={"/info/contact"}>Contact</ApplicationLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);
