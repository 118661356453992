import React, { useContext, useState } from 'react';
import { FormError, Settings } from "./models";
import { SubmitHandler, useForm } from "react-hook-form";
import { RecaptchaField } from "./ReactComponents";
import { SettingsContext } from "./Context";
import { EmailField, FieldContainer } from "./TypescriptComponents";
import { EmailValidation, ErrorResponse, HTTP_CLIENT } from "./Util";

interface ForgotPasswordFields {
    email: string;
    "g-recaptcha-response": string;
}
export const ForgotPasswordPage = () => {

    const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map<string, string>());
    const [message, setMessage] = useState<string>("");
    const { register, handleSubmit, formState: { errors } } = useForm<ForgotPasswordFields>();
    const settings = useContext<Settings | undefined>(SettingsContext);

    const onSubmit: SubmitHandler<ForgotPasswordFields> = data => {
        setMessage("");
        HTTP_CLIENT.post('/api/reset', data, {}, {useGlobalErrorHandling: false})
            .then(() => {
                setFormErrors(new Map());
                setMessage(`Instructions to reset your password have been sent to ${data.email}.`);
            })
            .catch((error: ErrorResponse<FormError>) => {
                if(error.isClientError()) {
                    setFormErrors(new Map(Object.entries(error.response?.data.response.field_errors)));
                }
            });
    };

    const copyMap = new Map<string, string>(formErrors);
    if(errors) {
        for (const field in errors) {
            const value = (errors as any)[field];
            if(value.message) {
                copyMap.set(field, value.message);
            }
        }
    }

    if(!settings) {
        return <React.Fragment />;
    }

    return <div className="registerform">
        <h1>Send password reset instructions</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FieldContainer error={copyMap.get("email")} label={"Email"}>
                <EmailField register={register("email", EmailValidation)} />
            </FieldContainer>
            <RecaptchaField registerFields={register("g-recaptcha-response")}/>
            <input type="submit" className="button" value={"Recover Password"} />
        </form>
        <p>{message}</p>
    </div>;
}
