import { useLocation } from "react-router-dom";
import { ApplicationLink } from './Routing';

export const MainMenu = (props: MainMenuProps) => {
    const componentLinks = props.links.map((link) => <MainMenuLink url={link.url} caption={link.caption} key={link.caption} />);
    return <div id="main-menu">
        <div className="column row">
            <ul className="menu vertical medium-horizontal">
                {componentLinks}
            </ul>
        </div>
    </div>;
};

interface MainMenuProps {
    links: Link[];
}

function MainMenuLink(link: Link) {
    const className = useLocation().pathname === link.url ? "is-active" : undefined;
    return <li className={className}>
        <ApplicationLink href={link.url}>{link.caption}</ApplicationLink>
    </li>;
}

export interface Link {
    url: string;
    caption: string;
}
