import React, { useMemo } from "react";

import { ColumnDef } from '@tanstack/react-table'

import { FilterConfig, ReactTable, TradeButton } from './SharedComponents';
import { Filter, GlobalFilter } from "./AthleteAppComponents";
import { Athlete, OwnedAthlete, Selection } from "./models";
// @ts-expect-error "Invalid type export"
import { Row } from "@tanstack/table-core/src/types";


const AthleteTable = ({
                          athletes,
                          afterDeadline,
                          columns,
                          ownedAthletes,
                          selectAthlete,
                          allowBuying,
                          balance,
                          activeSelection,
                          filterUpdate,
                          filter
                      }: {
    athletes: Athlete[],
    afterDeadline: boolean,
    columns: ColumnDef<any>[],
    ownedAthletes: OwnedAthlete[],
    selectAthlete: (athlete: Athlete, selected: boolean) => void,
    allowBuying: (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection) => boolean,
    balance: number,
    activeSelection: Selection,
    filterUpdate: (filter: Filter) => void,
    filter: Filter
}) => {

    const ownsAthlete = useMemo(() => (athlete: Athlete) => {
        return ownedAthletes.map(athlete => athlete.athlete_id).indexOf(athlete.athlete_id) > -1;
    }, [ownedAthletes]);

    type TableData = Athlete & { trade: React.JSX.Element };

    const data: TableData[] = useMemo(() => athletes.map(athlete => {
        return Object.assign({
            trade: <TradeButton afterDeadline={ afterDeadline }
                                athlete={ athlete }
                                isOwned={ ownsAthlete(athlete) }
                                allowBuying={ allowBuying(athlete, balance, ownedAthletes, activeSelection) }
                                selectAthlete={ selectAthlete }/>
        }, athlete);
    }), [athletes, ownedAthletes, ownsAthlete, balance]);

    const filterConfig: FilterConfig<Athlete, Filter> = useMemo(() => {
        return {
            filterFunction: (rows: Row<TableData>, globalFilterValue: Filter) => {
                const athlete = rows.original;
                return (athlete.price <= balance || !globalFilterValue.filterPrice || ownsAthlete(athlete)) &&
                    athlete.name.toLowerCase().indexOf(globalFilterValue.name.toLowerCase()) > -1
            },
            filterUpdate,
            createFilterComponent:
                (globalFilter: Filter | undefined, setGlobalFilter: (filter: Filter) => void) => <GlobalFilter
                    globalFilter={ globalFilter }
                    setGlobalFilter={ setGlobalFilter }
                />,
            initialFilter: filter
        }
    }, [balance, ownedAthletes, ownsAthlete]);

    return <ReactTable columns={ columns }
                       data={ data }
                       pageSize={ 10 }
                       filterConfig={ filterConfig }
    />;
};

export default AthleteTable;
