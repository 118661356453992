import React, { useContext, useEffect } from 'react';
import { Athlete, Cup, Settings, User } from './models';
import { ApplicationLink } from "./Routing";
import Skeleton from "react-loading-skeleton";
import {
    ApplicationComponents,
    Components,
    Context, CountryContext, CountryData,
    CupContext,
    SettingsContext,
    UserContext
} from "./Context";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "./scss/bib.scss";
import "./scss/flag.scss";
import "./scss/message.scss";
// @ts-expect-error the type is there
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";

export const UserDisplay = ({user}: {user: User}) => {
    const userContext = useContext<Context>(UserContext);
    const components = useContext<Components>(ApplicationComponents);
    let teamName;
    if(userContext.loggedInState.user?.user_id === user.user_id) {
        teamName = <strong>{user.team_name}</strong>
    }
    else {
        teamName = <span>{user.team_name}</span>
    }

    return <ApplicationLink href={ `/users/${user.user_id}` }>
        {components.flag(user.country)}<LeaderBibs user={user} /> {teamName}
    </ApplicationLink>
}

export const AthleteDisplay = ({athlete}: {athlete: Athlete}) => {
    const components = useContext<Components>(ApplicationComponents);
    return <ApplicationLink href={ `/athletes/${athlete.athlete_id}`}>
        {components.flag(athlete.country)}<span className={"athlete-name"}>{athlete.name}</span>
    </ApplicationLink>
}

export const LeaderBibs = ({user}: {user: User}) => {
    const cups = useContext<Cup[]>(CupContext);
    return <React.Fragment>
        {cups.filter(cup => cup.leaders.findIndex((leader) => leader.user_id === user.user_id) >= 0)
        .map((cup) => <LeaderBib key={cup.name} cup={cup} />)}
    </React.Fragment>;
}

export const LeaderBib = ({ cup }: { cup: Cup }) => {
    const url = new URL(cup.links.image)
    return <img key={ cup.name } className="cup_bib" src={ url.pathname } alt={ cup.name + " leader" } />
}

export const Flag = ({country}: {country?: string}) => {
    const countryContext = useContext<CountryData>(CountryContext);
    const countriesMap = countryContext.countriesByAlpha3;
    let alpha2 = undefined;
    if(country) {
        alpha2 = countriesMap?.get(country)?.alpha_2;
    }
    return <span
        className={`fi fi-${(alpha2 || "").toLowerCase()}`}/>
}

export const Reveal = ({visible, closeRequest, children, theme}:
                           {visible: boolean, closeRequest?: () => void, children: JSX.Element, theme: 'primary' | 'secondary' | 'alert'}) => {
    let containerClass = "messageContainer";
    if(visible) {
        containerClass += " visible";
    }
    let paddingClass = "padding";
    if(!closeRequest) {
        paddingClass += " modal";
    }
    return <div className={containerClass}>
        <div className={"message " + theme}>{ children }</div>
        <div className={paddingClass} onClick={ () => closeRequest && closeRequest() } />
    </div>;
}

const DEFAULT_SETTINGS = {
    hasHeaders: true,
    loadingRows: 10
}

export const Table = <T,>(
    {
        columns,
        data,
        config
    }:
        {
            columns: Column<T>[],
            data?: T[],
            config?: {
                hasHeaders?: boolean,
                loadingRows?: number
            }
        }) => {

    const Cell = ({data}: {data: CellData}) => {
        if(data.shouldRender === false) {
            return null;
        }
        let content;
        if(data.isLoading) {
            content = <Skeleton />;
        }
        else {
            content = data.content;
        }
        return <td { ...data.settings }>
            { content }
        </td>
    }

    config = Object.assign(Object.assign({}, DEFAULT_SETTINGS), config || {});

    let headers;
    if(config.hasHeaders) {
        headers =
            <thead>
                <tr>
                    { columns.map((column) => <th key={column.headerKey || column.header}>{ column.header }</th>) }
                </tr>
            </thead>;
    }

    let rows;

    const rowKeyFunction = (index: number) => index;
    const cellKeyFunction = (column: Column<T>, index: number) => (column.headerKey || column.header) + '-' + index;

    if(data) {
        rows = data.map((row, index) => <tr key={rowKeyFunction(index)}>
            { columns.map((column) =>
                <Cell key={cellKeyFunction(column, index)} data={column.dataFunction(row)} />
            ) }
        </tr>);
    }
    else {
        rows = Array.from(Array(config.loadingRows)).map((_, index) => <tr key={rowKeyFunction(index)}>
            { columns.map((column) =>
                <td key={cellKeyFunction(column, index)}>
                    <Skeleton />
                </td>
            ) }
        </tr>)
    }

    return <table>
        { headers }
        <tbody>
            { rows }
        </tbody>
    </table>
}

interface Column<T> {
    header?: string,
    headerKey?: string,
    dataFunction: (data: T) => CellData,
}

interface CellData {
    isLoading?: boolean;
    content: string | number | JSX.Element;
    shouldRender?: boolean;
    settings?: any;
}

export const RecaptchaField = ({registerFields} : {registerFields: UseFormRegisterReturn}) => {
    const settings = useContext<Settings | undefined>(SettingsContext);
    if (!settings) {
        return <React.Fragment/>;
    }

    const InnerComponent = () => {
        const { executeRecaptcha } = useGoogleReCaptcha();

        useEffect(() => {
            if(executeRecaptcha) {
                executeRecaptcha('yourAction').then((token) => {
                    registerFields.onChange({
                        target: {
                            value: token,
                            name: registerFields.name
                        }
                    })
                });
            }
        }, [executeRecaptcha]);
        return <></>;
    }

    return <InnerComponent />;
}
