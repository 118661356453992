import { useContext } from "react";
import { Context, Link, MainMenu, UserContext } from "@fantasy/components";

export const NhfnMainMenu = () => {
  const context = useContext<Context>(UserContext);
  if (context.loggedInState.loggedIn === undefined) {
    return <MainMenu links={[]} />;
  }
  const links: Link[] = [
    {
      url: "/info/rules",
      caption: "How to play",
    },
  ];
  if (context.loggedInState.loggedIn) {
    links.push(
      {
        url: "/athletes",
        caption: "Athletes",
      },
      {
        url: "/standings",
        caption: "Standings",
      },
      {
        url: "/leagues",
        caption: "Leagues",
      },
      {
        url: "/events",
        caption: "Events",
      },
    );
  }
  links.push({
    url: "/info/contact",
    caption: "Contact",
  });
  if (!context.loggedInState.loggedIn) {
    links.push(
      {
        url: "/register",
        caption: "Register",
      },
      {
        url: "/login",
        caption: "Log in",
      },
    );
  }

  return <MainMenu links={links} />;
};
