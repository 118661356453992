import { useContext } from 'react';
import { RankedUser, SignInError, Settings } from "./models";
import { useDoLogin } from "./Hooks";
import { ErrorResponse, HTTP_CLIENT, HTTP_CLIENT_JQUERY_ADAPTER, handleError } from "./Util";
import { SettingsContext } from "./Context";

import { useNavigate, createSearchParams } from "react-router-dom";
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const GoogleButton = ({text}: {text: 'signin_with' | 'signup_with' | 'continue_with' | 'signin'}) => {

    const login = useDoLogin()
    const navigate = useNavigate();
    const settings = useContext<Settings | undefined>(SettingsContext);

    function onSignIn(googleUser: CredentialResponse) {
        const token = googleUser.credential;
        HTTP_CLIENT_JQUERY_ADAPTER.post({
            url: '/api/googleLogin',
            data: { token },
            contentType: 'application/json',
            global: false
        }).then(() => {
            HTTP_CLIENT.get<RankedUser>('/api/me/rank')
                .then((response) => {
                    login(response.data);
                })
                .catch(() => {
                    window.location.href = '/';
                });
        }).catch((data: ErrorResponse<SignInError>) => {
            if(data.response?.status === 403) {
                navigate({
                    pathname: "/register",
                    search: createSearchParams(data.response?.data.user_data || {}).toString()
                });
            }
            else {
                handleError(data);
            }
        });
    }

    if(!settings || !settings.google_api_key) {
        return <></>;
    }

    return <GoogleOAuthProvider clientId={ settings.google_api_key }>
        <GoogleLogin onSuccess={(resp) => onSignIn(resp)} text={text} />
    </GoogleOAuthProvider>;
}

export const GoogleSignInButton = () => <GoogleButton text={'signin_with'} />;

export const GoogleRegisterButton = () => <GoogleButton  text={'signup_with'} />;
