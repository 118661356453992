export const ServerError = () => {
  return (
    <>
      <h1>Whoops, Server Error!</h1>
      <p>
        It looks like you found a bug in the code. Some times these things work
        themselves out, try going back to the &nbsp;<a href="/">start page</a>{" "}
        and start over. If the problem persist, we would really appreciate if
        you could reach out on{" "}
        <a href="https://twitter.com/fantasyxc">twitter</a> to let us know.
        Thanks!
      </p>
    </>
  );
};
