import { Params } from "react-router-dom";
import {
    Athlete,
    League,
    LeagueScore,
    LeagueWeekendResult,
    LeagueWeekendViewLoaderData,
    LoadedLeague,
    LoadedRaceData,
    LoadedUser,
    Race,
    RankedUser,
    User,
    Weekend
} from "./models";
import { requestData } from "./Util";
import { AthletesAppLoadedData } from "./SharedComponents";
import { SelectionResponse, WeekendResponse } from "./responses";

export const athleteLoader = async ({ params }: { params: Params }): Promise<Athlete> => {
    return await requestData<Athlete>(`/api/athletes/${ params.athleteId }`);
}

export const userLoader = async ({ params }: { params: Params }): Promise<LoadedUser> => {
    const user = await requestData<RankedUser>(`/api/users/${ params.userId }/rank`);
    const usersLeagues = await requestData<League[]>(`/api/users/${ params.userId }/leagues`);
    return { user, usersLeagues }
};

export const weekendLoader = async ({ params }: {params: Params<string>}): Promise<WeekendResponse> => {
    return await requestData<WeekendResponse>(`/api/weekends/${params.weekendId}`)
};

export const raceLoader = async ({ params }: {params: Params<string>}): Promise<LoadedRaceData> => {
    const race = await requestData<Race>(`/api/races/${params.raceId}`);
    const weekend = await requestData<WeekendResponse>(race.links.weekend);
    const selection = await requestData<SelectionResponse>(race.links.selection);

    return { race, selection, weekend };
}

export const leagueWeekendLoader = async ({ params }: {params: Params<string>}): Promise<LeagueWeekendViewLoaderData> => {
    const weekend = await requestData<WeekendResponse>(`/api/weekends/${params.weekendId}`);
    const leagueMembers = await requestData<RankedUser[]>(`/api/leagues/${encodeURIComponent(params.leagueName as string)}/members/`);
    const result = await requestData<LeagueWeekendResult>(`/api/leagues/${encodeURIComponent(params.leagueName as string)}/results/${params.weekendId}`);
    return {weekend, leagueMembers, result}
};

export const singleLeagueLoader = async ({ params }: { params: Params }): Promise<LoadedLeague> => {
    const league = requestData<League>(`/api/leagues/${ params.leagueName }`);
    const leagueScore = requestData<LeagueScore>(`/api/leagues/${ params.leagueName }/score`);
    const leagueMembers = requestData<RankedUser[]>(`/api/leagues/${ encodeURIComponent(params.leagueName as string) }/members/`);

    return { league: await league, leagueScore: await leagueScore, leagueMembers: await leagueMembers };
};

export const athletesAppLoader = async (): Promise<AthletesAppLoadedData> => {
    const money = await requestData<User>('/api/me/money');
    const weekend = new Weekend(await requestData<WeekendResponse>('/api/weekends/upcoming'));
    const athletes = await requestData<Athlete[]>('/api/athletes?filter_active=True&country=!RUS&country=!BLR');
    let nextSelection;

    const sortedSelections = weekend.selections.sort(
        (a, b) => {
            const trading_close_a = new Date(a.trading_close);
            const trading_close_b = new Date(b.trading_close);

            if (trading_close_a > trading_close_b) {
                return 1;
            } else if (trading_close_a < trading_close_b) {
                return -1;
            } else {
                return 0;
            }
        }
    );

    const remainingSelections = sortedSelections
        .filter((selection) => (new Date(selection.trading_close) > new Date()));
    if (remainingSelections.length) {
        nextSelection = remainingSelections[0];
    } else {
        nextSelection = sortedSelections[weekend.selections.length - 1]
    }

    return {
        athletes,
        loadedActiveSelection: nextSelection,
        loadedAfterDeadline: !weekend.trading_open,
        budget: money.budget,
        upcomingWeekend: weekend

    }
}
