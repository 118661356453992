import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { Athlete, AthleteResult, Race, RankedUser, ServerError, User } from "./models";
import { WeekendResponse } from "./responses";

const axiosWithoutErrorHandling = axios.create();
const standardAxiosInstance = axios.create();

standardAxiosInstance.interceptors.response.use(
    (response) => response,
    (errorResponse) => {
        const status = errorResponse.response?.status || 0;
        const error = {
            ...errorResponse,
            isClientError: () => status >= 400 && status < 500,
            isServerError: () => status >= 500 && status < 600
        }
        return Promise.reject(error);
    }
);
axiosWithoutErrorHandling.interceptors.response.use(
    (response) => response,
    (errorResponse) => {
        const status = errorResponse.response?.status || 0;
        const error = {
            ...errorResponse,
            isClientError: () => status >= 400 && status < 500,
            isServerError: () => status >= 500 && status < 600
        }
        return Promise.reject(error);
    }
);

const csrfTokenInterceptor = (config: InternalAxiosRequestConfig) => {
    config.headers["X-CSRFToken"] = getCookie("_csrf_token");
    return config;
};
standardAxiosInstance.interceptors.request.use(csrfTokenInterceptor);
axiosWithoutErrorHandling.interceptors.request.use(csrfTokenInterceptor);

class HttpClient {

    interceptors = new Map<string, { standardId: number, withoutErrorId: number }>();

    static DEFAULT_HEADERS = {
        contentType: "application/json"
    }

    static DEFAULT_CONFIG = {
        useGlobalErrorHandling: true
    }

    get<T = any>(url: string, config?: {useGlobalErrorHandling?: boolean, params?: any}): Promise<AxiosResponse<T>> {
        const newConfig = Object.assign({}, HttpClient.DEFAULT_CONFIG, config);
        return (newConfig.useGlobalErrorHandling ? standardAxiosInstance : axiosWithoutErrorHandling)
            .get<T>(url, {params: newConfig.params});
    }

    delete<T = any>(url: string, config?: {useGlobalErrorHandling?: boolean}): Promise<AxiosResponse<T>> {
        const newConfig = Object.assign({}, HttpClient.DEFAULT_CONFIG, config);
        return (newConfig.useGlobalErrorHandling ? standardAxiosInstance : axiosWithoutErrorHandling)
            .delete<T>(url);
    }

    post<T = any>(url: string, data: any, headers?: any, config?: {useGlobalErrorHandling?: boolean}): Promise<AxiosResponse<T>> {
        const mergedHeaders = Object.assign({}, HttpClient.DEFAULT_HEADERS, headers);
        const newConfig = Object.assign({}, HttpClient.DEFAULT_CONFIG, config);
        return (newConfig.useGlobalErrorHandling ? standardAxiosInstance : axiosWithoutErrorHandling)
            .post<T>(url, data, {headers: mergedHeaders});
    }

    put<T = any>(url: string, data: any, headers?: any, config?: {useGlobalErrorHandling?: boolean}): Promise<AxiosResponse<T>> {
        const mergedHeaders = Object.assign({}, HttpClient.DEFAULT_HEADERS, headers);
        const newConfig = Object.assign({}, HttpClient.DEFAULT_CONFIG, config);
        return (newConfig.useGlobalErrorHandling ? standardAxiosInstance : axiosWithoutErrorHandling)
            .put<T>(url, data, {headers: mergedHeaders});
    }

    patch<T = any>(url: string, data: any, headers?: any, config?: {useGlobalErrorHandling?: boolean}): Promise<AxiosResponse<T>> {
        const mergedHeaders = Object.assign({}, HttpClient.DEFAULT_HEADERS, headers);
        const newConfig = Object.assign({}, HttpClient.DEFAULT_CONFIG, config);
        return (newConfig.useGlobalErrorHandling ? standardAxiosInstance : axiosWithoutErrorHandling)
            .patch<T>(url, data, {headers: mergedHeaders});
    }
    
    addErrorHandling<T>(errorHandler: (error: ErrorResponse<T>) => void, skippable?: boolean) {
        standardAxiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                errorHandler(error);
                return Promise.reject(error);
            }
        );
        if(skippable === false) {
            axiosWithoutErrorHandling.interceptors.response.use(
                (response) => response,
                (error) => {
                    errorHandler(error);
                    return Promise.reject(error);
                }
            );
        }
    }

    addInterceptor(interceptor: (config: any) => any, interceptorId: string) {
        if(this.interceptors.has(interceptorId)) {
            console.warn("Got two interceptors with same id. Ejecting previous");
            this.removeInterceptor(interceptorId);
        }
        const standardId = standardAxiosInstance.interceptors.request.use(interceptor);
        const withoutErrorId = axiosWithoutErrorHandling.interceptors.request.use(interceptor);
        this.interceptors.set(interceptorId, {standardId, withoutErrorId});
    }

    removeInterceptor(id: string) {
        const interceptorIds = this.interceptors.get(id);
        if(!interceptorIds) {
            console.warn("Did not have interceptor with id: {}", id);
            return;
        }
        this.interceptors.delete(id);
        standardAxiosInstance.interceptors.request.eject(interceptorIds.standardId);
        axiosWithoutErrorHandling.interceptors.request.eject(interceptorIds.withoutErrorId);
    }

    addDefaultHeader(header: string, value: string) {
        standardAxiosInstance.defaults.headers.common[header] = value;
        axiosWithoutErrorHandling.defaults.headers.common[header] = value;
    }
}

class JqueryHttpClientAdapter {

    handleResponse<T>(promise: Promise<AxiosResponse<T>>, success?: (data: T) => void, error?: ({status}: {status: number}) => void) {
        let ret = promise
            .then((response) => response.data);
        if(success) {
            ret = ret
                .then((response) => {
                    success(response);
                    return response;
                });
        }
        if(error) {
            ret = ret.catch((errorResponse: ErrorResponse<any>) => {
                error({status: errorResponse.response?.status || 0});
                return Promise.reject(errorResponse);
            })
        }
        return ret;
    }

    get<T = any>({url, success, error, global}: JqueryParams<T>): Promise<T> {
        const promise = HTTP_CLIENT.get(url, {useGlobalErrorHandling: global !== false})
        return this.handleResponse(promise, success, error);
    }

    delete<T = any>({url, success, error, global}: JqueryParams<T>): Promise<T> {
        const promise = HTTP_CLIENT.delete(url, {useGlobalErrorHandling: global !== false})
        return this.handleResponse(promise, success, error);
    }

    post<T = any>({url, success, error, global, data, contentType}: PostParams<T>): Promise<T> {
        const promise = HTTP_CLIENT.post(url, data, {contentType}, {useGlobalErrorHandling: global !== false})
        return this.handleResponse(promise, success, error);
    }

    put<T = any>({url, success, error, global, data, contentType}: PostParams<T>): Promise<T> {
        const promise = HTTP_CLIENT.put(url, data, {contentType}, {useGlobalErrorHandling: global !== false})
        return this.handleResponse(promise, success, error);
    }

    patch<T = any>({url, success, error, global, data, contentType}: PostParams<T>): Promise<T> {
        const promise = HTTP_CLIENT.patch(url, data, {contentType}, {useGlobalErrorHandling: global !== false})
        return this.handleResponse(promise, success, error);
    }
}

interface JqueryParams<T> {
    url: string;
    success?: (data: T) => void;
    error?: ({status}: {status: number}) => void;
    global?: boolean;
}

interface PostParams<T> extends JqueryParams<T> {
    data: any;
    contentType?: string;
}

export const HTTP_CLIENT_JQUERY_ADAPTER = new JqueryHttpClientAdapter();
export const HTTP_CLIENT = new HttpClient();

export type ErrorResponse<T> = AxiosError<T> & {isClientError: () => boolean; isServerError: () => boolean};

export function handleError(data: ErrorResponse<ServerError>) {
    const errorObject = data.response?.data;
    if(errorObject && errorObject.message) {
        displayErrorMessage(errorObject.message)
    }
    else {
        displayErrorMessage("We're sorry. We could not do that. Please try again later")
    }
}

export function hasDefaultTeamName(user: User) {
    return user.team_name === user.user_name+"'s Team"
}

export function displayErrorMessage(message: string) {
    const customEvent = new CustomEvent('errorMessage', {detail: {message}});
    document.dispatchEvent(customEvent);
}

export async function requestData<T>(url: string): Promise<T> {
    return HTTP_CLIENT.get<T>(url, {useGlobalErrorHandling: false})
        .then((response) => {
            return response.data;
        })
        .catch((error: any) => {
            if (error.response?.status === 404) {
                throw new Response("Not Found", {status: 404});
            }
            if (error.response?.status === 401) {
                throw new Response("Not logged in", {status: 401});
            }
            if (error.response?.status === 403) {
                throw new Response("Not authorized", {status: 403});
            }
            throw error;
        })
}

const EMAIL_ADDRESS_PATTERN = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const EmailValidation = { pattern: {
        value: EMAIL_ADDRESS_PATTERN,
        message: "Email is not valid"
    },
    required: {
        value: true,
        message: "Email must be provided"
    }
};

export class HttpService {
    async overallRanking(limit: number): Promise<RankedUser[]> {
        return HTTP_CLIENT.get<RankedUser[]>(`/api/cups/Overall/leaderboard?limit=${limit}`)
        .then((response) => response.data);
    }

    async raceResults(raceId: number): Promise<AthleteResult[]> {
        return HTTP_CLIENT.get<AthleteResult[]>(`/api/races/${raceId}/results/athletes`)
        .then((response) => response.data);
    }

    async weekend(weekendId: number): Promise<WeekendResponse> {
        return HTTP_CLIENT.get<WeekendResponse>(`/api/weekends/${weekendId}`)
        .then((response) => response.data);
    }

    async lastScoredRace(gender: 'm' | 'f' | 'mixed'): Promise<Race> {
        return HTTP_CLIENT.get<Race>(`/api/races/latest/${gender}`)
        .then((response) => response.data);
    }
}

export const getCookie = (cookieName: string): string | undefined => {
    for (const cookie of document.cookie.split(';')) {
        const [key, value] = cookie.split('=');
        if(key.trim() === cookieName) {
            return value;
        }
    }
}

export const formatAmount = (amount: number) => `$${formatNumber(amount)}`;
export const formatNullableAmount = <T,> (amount: number | undefined | null, defaultValue: T): T | string => amount !== undefined && amount !== null ? formatAmount(amount) : defaultValue;
export const formatScore = (score: number) => `${formatNumber(score)}\u00A0p.`;
export const formatNullableScore = <T,> (score: number | undefined | null, defaultValue: T): T | string => score !== undefined && score !== null ? formatScore(score) : defaultValue;
export const formatRank = (rank: number) => formatNumber(rank);
export const formatNullableRank = <T,> (rank: number | undefined | null, defaultValue: T): T | string => rank !== undefined && rank !== null ? formatRank(rank) : defaultValue;
export const formatNumber = (number: number) => number.toLocaleString();

export const calculatePPG = (athlete: Athlete, athletePrices: Map<number, number>, score: number): number | undefined => {

    const athletePrice = athletePrices.get(athlete.athlete_id);
    if(!athletePrice) {
        return undefined
    }
    return Math.round((score * 100000 / athletePrice)) / 100.0;
}

export const formatPPG = (ppg: number | undefined): string => {
    if (!ppg) {
        return '∞'
    }
    return formatNumber(ppg);
}
