import { useContext } from "react";
import { ApplicationLink } from "./Routing";
import { Settings } from "./models";
import { SettingsContext } from "./Context";
import skitracks from "./img/skitracks.jpg"

export const PageNotFound = () => <>
    <img src={ skitracks } alt="Lost" />
        <h1>Uh oh. Did you get lost?</h1>
        <p>
            It looks you got lost. Do you want to get back to the&nbsp;
            <ApplicationLink href={'/'}>start page</ApplicationLink>?
        </p>
</>

export const ServerError = () => {
    const settings = useContext<Settings | undefined>(SettingsContext);
    const contactUs = settings ? <>on <a href={`mailto:${settings.mail_address}`}>{ settings.mail_address }</a></> : <></>
    return <>
        <h1>Whoops, Server Error!</h1>
        <p>
            It looks like you found a bug in the code. Some times these things work themselves out, try going back to the&nbsp;
            <a href="/">start page</a> and start over. If the problem persist, we would
            really appreciate if you could reach out and let us know {contactUs}. Thanks!
        </p>
    </>
}
