import { useEffect, useState } from "react";

export type Filter = { name: string, filterPrice: boolean };

export const GlobalFilter = ({
                          globalFilter,
                          setGlobalFilter
                      }: { globalFilter?: Filter, setGlobalFilter: (filter: Filter) => void}) => {
    const [name, setName] = useState<string>(globalFilter?.name || "");
    const [filterPrice, setFilterPrice] = useState<boolean>(globalFilter?.filterPrice || false);

    useEffect(() => {
        setGlobalFilter({name, filterPrice });
    }, [filterPrice, name, setGlobalFilter]);

    return <div>
        <fieldset>
            <input className="table_filter"
                   type="search"
                   value={name}
                   onChange={e => setName(e.target.value)}
                   placeholder="Search athlete" />
        </fieldset>
        <fieldset className='filter-container'>
            <label className='within-budget-label'>Only show athletes I can afford:</label>
            <input type="checkbox" checked={filterPrice}
                   onChange={e => setFilterPrice(e.target.checked)}/>
        </fieldset>
    </div>;
}